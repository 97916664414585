import React, { useCallback, useEffect, useState } from 'react';
import { storeSelect, floorIdSelect, storeNameSelect, storeLidarTokenSelect } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { isNull } from 'lodash';
import { getCookieLidar } from 'utils/get_cookie';
import { useLocation } from 'react-router';
import { ENUM_SELECT_STORE } from '@bis/constants/enums';
import { CFN_isIncludeAllStore, CFN_isIncludeAllBeatAllBeatbox, CFN_isInitstore } from 'utils/commFunction';
import { apiStoreMonitoringList } from 'services/apicp';
import { CFN_choiceStoreType } from 'utils/commUrlChecker';
import { Box } from '@mui/material';
import ModuleAutocomplete from './ModuleAutocomplete';

const HeaderStoreList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const storeList = useSelector((state) => (state.auth.authUser ? state.auth.authUser.storeList : []));
  const selectedLidarToken = useSelector(({ common }) => common.selectedLidarToken);
  const findPosition = location.pathname.lastIndexOf('/');
  const paramsStorecode = location.pathname.substring(findPosition + 1);
  const storeType = CFN_choiceStoreType(location.pathname);

  const [store, setStore] = useState([]);
  const [filteredStore, setFilteredStore] = useState([]);

  const [value, setValue] = useState(filteredStore[0]);

  const normalDispatch = useCallback(
    (storecode, stores) => {
      if (isNull(storecode) || storecode === 'null' || CFN_isInitstore(storecode)) {
        let code = null;
        let floor = null;
        let name = null;
        if (stores.length > 0) {
          code = stores[0]?.code;
          floor = stores[0]?.floorId;
          name = stores[0]?.name;
        }

        dispatch(storeSelect(code));
        dispatch(floorIdSelect(floor));
        dispatch(storeNameSelect(name));
      } else {
        const clickedoption = stores.find((item) => item.code === storecode);
        if (clickedoption) {
          dispatch(storeSelect(clickedoption?.code));
          dispatch(floorIdSelect(clickedoption?.floorId));
          dispatch(storeNameSelect(clickedoption?.name));
        }
      }
    },
    [dispatch],
  );

  const apiMonitoringList = useCallback(
    (storecode) => {
      apiStoreMonitoringList()().then((res) => {
        setFilteredStore(res);
        normalDispatch(storecode, res);
      });
    },
    [normalDispatch],
  );

  useEffect(() => {
    if (filteredStore.length !== 0) {
      CFN_isInitstore(paramsStorecode)
        ? setValue(filteredStore[0])
        : setValue(filteredStore.find((store) => store?.code === paramsStorecode));
    }
  }, [filteredStore]);

  useEffect(() => {
    const filteredStoreList = () => {
      if (Object.keys(storeList).length <= 2) {
        const filtered = storeList.filter((store) => store.code !== 'ALL');
        return setStore(filtered);
      } else {
        return setStore(storeList);
      }
    };

    filteredStoreList();
  }, [storeList]);

  useEffect(() => {
    //lidar token state save , 라이다 토큰이 다르면 state 업데이트 합니다.
    const lidarToken = getCookieLidar();
    if (lidarToken !== selectedLidarToken) {
      dispatch(storeLidarTokenSelect(lidarToken));
    }
  }, [dispatch, selectedLidarToken]);

  useEffect(() => {
    const loadStoreList = async () => {
      if (store.length < 1) return;

      switch (storeType) {
        case ENUM_SELECT_STORE.STORES_PLUS_ALL: {
          // all 만 포함
          const filteringStores = store.filter((store) => CFN_isIncludeAllBeatAllBeatbox(store?.code) === false);
          setFilteredStore(filteringStores);
          normalDispatch(paramsStorecode, filteringStores);
          break;
        }

        case ENUM_SELECT_STORE.STORES: {
          // all, allbeat, allbeatbox 제외
          const filteringStores = store.filter((store) => CFN_isIncludeAllStore(store?.code) === false);
          setFilteredStore(filteringStores);
          normalDispatch(paramsStorecode, filteringStores);
          break;
        }

        case ENUM_SELECT_STORE.STORES_LIDAR: {
          // all, allbeat, allbeatbox 제외 후 lidar floorid가 유효한 매장
          const stores = store.filter((store) => CFN_isIncludeAllStore(store?.code) === false);
          setFilteredStore(stores);
          normalDispatch(paramsStorecode, stores);
          break;
        }

        case ENUM_SELECT_STORE.STORES_OPERATE: {
          // all을 포함하지만 운영중인(폐점하지 않은) 매장
          if (Object.keys(storeList).length <= 2) {
            return store;
          } else {
            return apiMonitoringList(paramsStorecode);
          }
        }

        default:
          break;
      }
    };

    loadStoreList();
  }, [storeType, storeList, store, paramsStorecode, normalDispatch, dispatch, apiMonitoringList]);

  useEffect(() => {
    const clickedOption = filteredStore.find((store) => store?.code === value?.code);
    if (clickedOption) {
      dispatch(storeSelect(clickedOption?.code));
      dispatch(floorIdSelect(clickedOption?.floorId));
      dispatch(storeNameSelect(clickedOption?.name));
    }
  }, [value]);

  const OutPut = () => {
    if (storeType === ENUM_SELECT_STORE.NONE) {
      return null;
    }
    if (filteredStore.length > 1) {
      return (
        <ModuleAutocomplete
          value={value}
          setValue={setValue}
          options={filteredStore}
          storeType={storeType}
          storeList={storeList}
        />
      );
    }
    if (filteredStore.length === 1) {
      return (
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          <span style={{ fontSize: 22, color: '#fff', fontWeight: 'bold' }}>{filteredStore[0]?.name}</span>
        </Box>
      );
    }
    return null;
  };

  return <OutPut />;
};

export default React.memo(HeaderStoreList);
