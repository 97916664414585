import React from 'react';
import SidebarToggleHandler from '@coremat/CmtLayouts/Vertical/SidebarToggleHandler';
import Toolbar from '@mui/material/Toolbar';
import { Box } from '@mui/material';
import HeaderProfile from './HeaderProfile';
import HeaderStoreList from './HeaderStoreList';
import styled from 'styled-components';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import useClasses from 'hook/useClasses';

const styles = (theme) => ({
  root: {
    backgroundColor: '#222',
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 64,
    [theme.breakpoints.up('md')]: {
      minHeight: 72,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 48,
      paddingRight: 48,
    },
  },

  searchIcon: {
    [theme.breakpoints.down('md')]: {
      padding: 9,
    },
  },
});

const Header = () => {
  const classes = useClasses(styles);

  return (
    <Toolbar className={classes.root}>
      <NotificationContainer />
      <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
      <StyledSearchBox>
        <StyledBox>
          <HeaderStoreList />
        </StyledBox>
      </StyledSearchBox>
      {/* <LanguageSwitcher />  언어 선택 필요시 주석 해제 할것 */}
      <HeaderProfile />
    </Toolbar>
  );
};

export default Header;

const StyledSearchBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: 353,
  height: 48,
  //세로모드 모바일 디바이스 (가로 해상도가 576px 보다 작은 화면에 적용)
  '@media screen and (max-width: 575px)': {
    width: 280,
  },
});
