import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router';
import Login from 'routes/Auth/Login';
import ChangePasswordPage from './Auth/ChangePassword';

const PrivateRoute = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { authUser } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (!authUser) {
      token ? navigate(`/change/${token}`) : navigate('/signin', { replace: true });
    }
  }, [authUser, navigate]);

  if (!authUser) {
    return token ? <ChangePasswordPage /> : <Login />;
  } else {
    return token ? <ChangePasswordPage /> : <Outlet />;
  }
};

export default PrivateRoute;
