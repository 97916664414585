/**
 * 개인정보 처리방침 HTML
 */
export const agree_personalInfo = `
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=ks_c_5601-1987">
<meta name=Generator content="Microsoft Word 15 (filtered)">

<style id="dynCom" type="text/css"><!-- --></style>
<script language="JavaScript"><!--
function msoCommentShow(anchor_id, com_id)
{
	if(msoBrowserCheck()) 
		{
		c = document.all(com_id);
		a = document.all(anchor_id);
		if (null != c && null == c.length && null != a && null == a.length)
			{
			var cw = c.offsetWidth;
			var ch = c.offsetHeight;
			var aw = a.offsetWidth;
			var ah = a.offsetHeight;
			var x  = a.offsetLeft;
			var y  = a.offsetTop;
			var el = a;
			while (el.tagName != "BODY") 
				{
				el = el.offsetParent;
				x = x + el.offsetLeft;
				y = y + el.offsetTop;
				}
			var bw = document.body.clientWidth;
			var bh = document.body.clientHeight;
			var bsl = document.body.scrollLeft;
			var bst = document.body.scrollTop;
			if (x + cw + ah / 2 > bw + bsl && x + aw - ah / 2 - cw >= bsl ) 
				{ c.style.left = x + aw - ah / 2 - cw; }
			else 
				{ c.style.left = x + ah / 2; }
			if (y + ch + ah / 2 > bh + bst && y + ah / 2 - ch >= bst ) 
				{ c.style.top = y + ah / 2 - ch; }
			else 
				{ c.style.top = y + ah / 2; }
			c.style.visibility = "visible";
}	}	}
function msoCommentHide(com_id) 
{
	if(msoBrowserCheck())
		{
		c = document.all(com_id);
		if (null != c && null == c.length)
		{
		c.style.visibility = "hidden";
		c.style.left = -1000;
		c.style.top = -1000;
		} } 
}
function msoBrowserCheck()
{
	ms = navigator.appVersion.indexOf("MSIE");
	vers = navigator.appVersion.substring(ms + 5, ms + 6);
	ie4 = (ms > 0) && (parseInt(vers) >= 4);
	return ie4;
}
if (msoBrowserCheck())
{
	document.styleSheets.dynCom.addRule(".msocomanchor","background: infobackground");
	document.styleSheets.dynCom.addRule(".msocomoff","display: none");
	document.styleSheets.dynCom.addRule(".msocomtxt","visibility: hidden");
	document.styleSheets.dynCom.addRule(".msocomtxt","position: absolute");
	document.styleSheets.dynCom.addRule(".msocomtxt","top: -1000");
	document.styleSheets.dynCom.addRule(".msocomtxt","left: -1000");
	document.styleSheets.dynCom.addRule(".msocomtxt","width: 33%");
	document.styleSheets.dynCom.addRule(".msocomtxt","background: infobackground");
	document.styleSheets.dynCom.addRule(".msocomtxt","color: infotext");
	document.styleSheets.dynCom.addRule(".msocomtxt","border-top: 1pt solid threedlightshadow");
	document.styleSheets.dynCom.addRule(".msocomtxt","border-right: 2pt solid threedshadow");
	document.styleSheets.dynCom.addRule(".msocomtxt","border-bottom: 2pt solid threedshadow");
	document.styleSheets.dynCom.addRule(".msocomtxt","border-left: 1pt solid threedlightshadow");
	document.styleSheets.dynCom.addRule(".msocomtxt","padding: 3pt 3pt 3pt 3pt");
	document.styleSheets.dynCom.addRule(".msocomtxt","z-index: 100");
}
// --></script>
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:굴림;
	panose-1:2 11 6 0 0 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"맑은 고딕";
	panose-1:2 11 5 3 2 0 0 2 0 4;}
@font-face
	{font-family:"HCR Dotum";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"@맑은 고딕";}
@font-face
	{font-family:"@굴림";
	panose-1:2 11 6 0 0 1 1 1 1 1;}
@font-face
	{font-family:"@HCR Dotum";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:115%;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕";}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"메모 텍스트 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕";}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"머리글 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:115%;
	layout-grid-mode:char;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕";}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"바닥글 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:115%;
	layout-grid-mode:char;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕";}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:purple;
	text-decoration:underline;}
p
	{margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:굴림;}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{mso-style-link:"메모 주제 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕";
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-link:"풍선 도움말 텍스트 Char";
	margin:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	text-autospace:none;
	word-break:break-hangul;
	font-size:9.0pt;
	font-family:"맑은 고딕";}
p.MsoRMPane, li.MsoRMPane, div.MsoRMPane
	{margin:0cm;
	font-size:10.0pt;
	font-family:"맑은 고딕";}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:40.0pt;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:115%;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕";}
span.Char
	{mso-style-name:"머리글 Char";
	mso-style-link:머리글;}
span.Char0
	{mso-style-name:"바닥글 Char";
	mso-style-link:바닥글;}
span.Char1
	{mso-style-name:"메모 텍스트 Char";
	mso-style-link:"메모 텍스트";}
span.Char2
	{mso-style-name:"메모 주제 Char";
	mso-style-link:"메모 주제";
	font-weight:bold;}
span.Char3
	{mso-style-name:"풍선 도움말 텍스트 Char";
	mso-style-link:"풍선 도움말 텍스트";
	font-family:"맑은 고딕";}
p.Pa11, li.Pa11, div.Pa11
	{mso-style-name:Pa11;
	margin:0cm;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"HCR Dotum",sans-serif;}
span.A9
	{mso-style-name:A9;
	font-family:"HCR Dotum",sans-serif;
	color:black;}
p.Pa42, li.Pa42, div.Pa42
	{mso-style-name:Pa42;
	margin:0cm;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"HCR Dotum",sans-serif;}
p.Pa76, li.Pa76, div.Pa76
	{mso-style-name:Pa76;
	margin:0cm;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"HCR Dotum",sans-serif;}
p.Pa77, li.Pa77, div.Pa77
	{mso-style-name:Pa77;
	margin:0cm;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"HCR Dotum",sans-serif;}
.MsoChpDefault
	{font-family:"맑은 고딕";}
.MsoPapDefault
	{margin-bottom:10.0pt;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:115%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:3.0cm 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=KO link=blue vlink=purple style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
background:white;text-autospace:ideograph-numeric ideograph-other;word-break:
keep-all'><b><span style='font-size:16.0pt;line-height:115%;color:black'>개인정보 처리방침</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span style='line-height:
115%;color:black'>주식회사 비트코퍼레이션<span lang=EN-US>(</span>이하 ‘회사’라 합니다<span
lang=EN-US>)</span>은 회원의 개인정보를 중요시하며<span lang=EN-US>, </span></span><span
style='line-height:115%;color:black'>「</span><span style='line-height:115%;
color:black'>정보통신망 이용촉진 및 정보보호에 관한 법률</span><span style='line-height:115%;
color:black'>」</span><span style='line-height:115%;color:black'> 및 </span><span
style='line-height:115%;color:black'>「</span><span style='line-height:115%;
color:black'>개인정보보호법</span><span style='line-height:115%;color:black'>」<span
lang=EN-US>,</span></span><span lang=EN-US style='line-height:115%;color:black'>
</span><span style='line-height:115%;color:black'>「</span><span
style='line-height:115%;color:black'>위치정보의 보호 및 이용 등에 관한 법률</span><span
style='line-height:115%;color:black'>」 </span><span style='line-height:115%;
color:black'>등 관계 법령을 준수하고 있습니다<span lang=EN-US>. </span>회사는 </span><span
style='line-height:115%;color:black'>「개인정보보호법」 제<span lang=EN-US> 30</span>조에 따라
</span><span style='line-height:115%;color:black'>개인정보처리방침을 통하여 회원이 제공하는 개인정보가 어떠한
용도와 방식으로 이용되고 있으며<span lang=EN-US>, </span>개인정보 보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다<span
lang=EN-US>. </span>회사는 개인정보 처리방침을 개정하는 경우 웹사이트 또는 모바일 어플리케이션 공지사항<span
lang=EN-US>(</span>또는 개별공지<span lang=EN-US>)</span>을 통하여 공지할 것입니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:38.0pt;text-indent:-18.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><b><span lang=EN-US
style='line-height:115%'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='line-height:115%;color:black'>수집 및 이용하는 개인정보의
항목<span lang=EN-US><br>
<br>
</span></span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:36.0pt;text-indent:-18.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>가.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>어플리케이션</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:58.0pt;text-indent:-20.0pt'><b><span lang=EN-US>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b>회원
가입 시 수집되는 항목</b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:76.0pt;text-indent:-18.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>이름<span
lang=EN-US>, </span>생년월일<span lang=EN-US>, ID/</span>비밀번호<span lang=EN-US>, </span>통신사
및 휴대폰번호<span lang=EN-US>, </span>성별</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:58.0pt;text-indent:-20.0pt'><b><span lang=EN-US>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b>서비스
이용 과정에서 수집되는 항목 </b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:76.0pt;text-indent:-18.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>매장정보<span
lang=EN-US>, </span>주문정보<span lang=EN-US>, </span>서비스 이용기록<span lang=EN-US>, </span>접속로그<span
lang=EN-US>(</span>접속일시<span lang=EN-US>), </span>결제정보<span lang=EN-US>, </span>이용정지
기록<span lang=EN-US>, </span>접속<span lang=EN-US>IP</span>정보<span lang=EN-US>, </span>쿠키<span
lang=EN-US>, </span>쿠폰 사용 정보<span lang=EN-US>, </span>멤버십 정보<span lang=EN-US>, </span>기기정보<span
lang=EN-US>(</span>단말기<span lang=EN-US>/OS </span>종류<span lang=EN-US>, Device
ID </span>등<span lang=EN-US>)</span></p>

<p class=MsoListParagraph align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:58.0pt;text-align:left;text-indent:-20.0pt;
text-autospace:ideograph-numeric ideograph-other;word-break:keep-all'><b><span
lang=EN-US style='line-height:115%'>③<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='line-height:115%'>이벤트 응모 등 진행 및 고객 응대<span
lang=EN-US>(</span>제휴<span lang=EN-US>&#8729;</span>가맹 문의 포함<span lang=EN-US>) </span>과정에서
회원 가입 시 수집하지 않았던 개인정보를 추가로 수집할 때에는 해당 서비스 이용자 동의 하에 개인정보가 추가 수집될 수 있으며<span
lang=EN-US>, </span>추가 수집 및 이용하는 개인정보의 항목은 아래와 같습니다<span lang=EN-US>.</span></span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:76.0pt;text-indent:-18.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>주소<span
lang=EN-US>, </span>이메일</p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:36.0pt;text-indent:-18.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>나.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span lang=EN-US style='line-height:115%;color:black'>CP PAGE</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
8.0pt;margin-left:58.0pt;text-indent:-20.0pt;line-height:normal'><span
lang=EN-US>①<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>서비스 가입 시 수집되는 항목</p>

<p class=MsoListParagraph style='margin-left:56.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>이름<span lang=EN-US>(</span>닉네임<span lang=EN-US>), </span>이메일<span
lang=EN-US>, </span>비밀번호</p>

<p class=MsoListParagraph style='margin-left:58.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US>②<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>서비스 이용 과정에서 수집되는 항목 </p>

<p class=MsoListParagraph style='margin-left:56.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>서비스 이용기록<span lang=EN-US>, </span>접속로그<span lang=EN-US>(</span>접속일시<span
lang=EN-US>), </span>이용정지 기록<span lang=EN-US>, </span>접속<span lang=EN-US>IP</span>정보<span
lang=EN-US>, </span>쿠키<span lang=EN-US>, </span>기기정보<span lang=EN-US>(</span>단말기<span
lang=EN-US>/OS </span>종류<span lang=EN-US>, Device ID </span>등<span lang=EN-US>)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:38.0pt;text-indent:-18.0pt'><b><span lang=EN-US
style='line-height:115%'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='line-height:115%'>개인정보의 수집 및 이용 목적 <span
lang=EN-US><br>
</span></span></b><span style='line-height:115%'>회사는 다음의 목적을 위하여 개인정보를 처리합니다<span
lang=EN-US>. </span>처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며<span lang=EN-US>, </span>이용
목적이 변경되는 경우에는 「개인정보보호법」 제<span lang=EN-US>18</span>조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할
예정입니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span lang=EN-US style='line-height:
115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:38.0pt;text-indent:-18.0pt'><span lang=EN-US style='line-height:
115%'>가.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='line-height:115%'>어플리케이션</span></p>

<p class=MsoNormal style='margin-bottom:0cm'><b><span lang=EN-US
style='line-height:115%'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:55.45pt;text-indent:-20.0pt'><span lang=EN-US>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span>어플리케이션
회원 서비스 제공</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>회원가입 및 본인확인</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>서비스 및 상품 제공을 위한 결제</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>쿠폰 제공 및 이용<span lang=EN-US>, </span>선물하기</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>충전카드 분실신고<span lang=EN-US>, </span>잔액환불<span lang=EN-US>, </span>선물하기</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>스탬프<span lang=EN-US>&amp;</span>포인트 적립</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>공지사항 전달</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>비트크루 멤버십 트로피 적립</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>이벤트 참여</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>매장인증 및 인증매장 혜택 제공</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>각종 고지와 통지</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>고충처리 목적 </p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt'><span lang=EN-US>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:55.45pt;text-indent:-20.0pt'><span lang=EN-US>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span>마케팅
정보 활용</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>신규서비스<span lang=EN-US>(</span>제품<span lang=EN-US>) </span>개발 및 특화</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>이벤트 등 광고성 정보 전달</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>인구 통계학적 특성에 따른 서비스 제공 및 광고게재</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>접속빈도 파악 또는 회원의 서비스 이용에 대한 통계</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt'><span lang=EN-US>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:55.45pt;text-indent:-20.0pt'><span lang=EN-US>③<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span>이용자
보호 및 서비스 운영</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>부정 사용자 제한 조치</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>부정 사용 방지 및 제재</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>분쟁조정을 위한 기록 보존</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>민원처리 및 서비스 개선</p>

<p class=MsoNormal style='margin-bottom:0cm'><span lang=EN-US>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:55.45pt;text-indent:-20.0pt'><span lang=EN-US>④<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span>기타</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>키오스크 주문 알림톡</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:75.45pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>식권대장 결제 <span lang=EN-US>push </span>알림</p>

<p class=MsoNormal style='margin-bottom:0cm'><span lang=EN-US>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:38.0pt;text-indent:-18.0pt'><span lang=EN-US>나.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
lang=EN-US>CP PAGE</span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span lang=EN-US>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>이용자 식별 및 등록</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>이용자 탈퇴 및 자격상실<span lang=EN-US>, </span>제재</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>설문조사<span lang=EN-US>(</span>마케팅 정책 동의 여부 등<span lang=EN-US>)</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>공지사항</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>약관 변경 고지</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>민원처리</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>기타 서비스 이용에 부수되는 기능 일체</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt'><span lang=EN-US>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:38.0pt;text-indent:-18.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><b><span lang=EN-US
style='line-height:115%'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='line-height:115%;color:black'>개인정보의 보유 및 이용기간</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
8.0pt;margin-left:38.0pt;text-indent:-20.0pt'><span lang=EN-US>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span>회사는
회원 가입일로부터 서비스를 제공하는 기간 동안에 한하여 회원의 개인정보를 보유 및 이용하게 됩니다<span lang=EN-US>. </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
8.0pt;margin-left:38.0pt;text-indent:-20.0pt'><span lang=EN-US>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span>회사는
회원이 탈퇴를 요청하는 경우<span lang=EN-US>, </span>개인정보의 수집 및 이용에 대한 동의를 철회하는 경우<span
lang=EN-US>, </span>수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우에는 회원의 개인정보를 지체 없이 파기합니다<span
lang=EN-US>. </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
8.0pt;margin-left:38.0pt;text-indent:-20.0pt'><span lang=EN-US>③<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span>전항에도
불구하고<span lang=EN-US>, </span>회사는 내부방침 및 관계법령의 규정에 의하여 보존할 필요가 있는 경우에는 아래와 같이 일정한
기간 동안 회원정보를 보관합니다<span lang=EN-US>. </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:70.0pt;text-indent:-20.0pt'><span lang=EN-US style='font-family:
Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span>내부방침에
의한 일시적 정보보유 </p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:90.0pt;text-indent:-20.0pt'><span lang=EN-US>가.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span>회원 고유번호</p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:40.0pt;text-indent:50.0pt'><span lang=EN-US>- </span>보존근거<span
lang=EN-US>: </span>회원탈퇴시 받은 동의</p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:40.0pt;text-indent:50.0pt'><span lang=EN-US>- </span>보존기간<span
lang=EN-US>: </span>회원 탈퇴일로부터 <span lang=EN-US>30</span>일</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:90.0pt;text-indent:-20.0pt'><span lang=EN-US>나.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span>휴면 회원</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:110.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>보존근거 <span lang=EN-US>: </span><span style='line-height:115%'>「개인정보보호법」</span>
제<span lang=EN-US>39</span>조의<span lang=EN-US>6 </span>및 동법 시행령 제<span
lang=EN-US>48</span>조의<span lang=EN-US>5, </span>가입시 받은 동의</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:110.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>보존기간 <span lang=EN-US>: </span>휴면 회원 전환일로부터 <span lang=EN-US>5</span>년</p>

<p class=MsoNormal style='margin-bottom:0cm'><span lang=EN-US>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:70.0pt;text-indent:-20.0pt'><span lang=EN-US style='font-family:
Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span>관계법령에
의한 정보보유</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:88.0pt;text-indent:-18.0pt'><span lang=EN-US>가.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span>서비스이용기록<span
lang=EN-US>, </span>접속로그<span lang=EN-US>, </span>접속<span lang=EN-US>IP</span>정보</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:110.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>보존근거<span lang=EN-US>: </span><span style='line-height:115%'>「</span>통신비밀보호법<span
style='line-height:115%'>」</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:110.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>보존기간<span lang=EN-US>: 3</span>개월</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:88.0pt;text-indent:-18.0pt'><span lang=EN-US>나.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span>계약 또는 청약철회 등에 관한
기록</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:110.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>보존근거<span lang=EN-US>: </span><span style='line-height:115%'>「</span>전자상거래등에서의
소비자 보호에 관한 법률<span style='line-height:115%'>」</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:110.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>보존기간<span lang=EN-US>: 5</span>년</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:88.0pt;text-indent:-18.0pt'><span lang=EN-US>다.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span>대금결제 및 재화 등의 공급에
관한 기록</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:110.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>보존근거<span lang=EN-US>: </span><span style='line-height:115%'>「</span>전자상거래등에서의
소비자 보호에 관한 법률<span style='line-height:115%'>」</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:110.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>보존기간<span lang=EN-US>: 5</span>년</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:88.0pt;text-indent:-18.0pt'><span lang=EN-US>라.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span>소비자의 불만 또는 분쟁처리에
관한 기록</p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:110.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>보존근거<span lang=EN-US>: </span><span style='line-height:115%'>「</span>전자상거래등에서의
소비자 보호에 관한 법률<span style='line-height:115%'>」</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:110.0pt;text-indent:-20.0pt'><span lang=EN-US>-<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>보존기간<span lang=EN-US>: 3</span>년</p>

<p class=MsoNormal style='margin-bottom:0cm'><span lang=EN-US>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-indent:-18.0pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='line-height:115%'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
style='line-height:115%;color:black'>개인정보의 제공 및 위탁</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;text-indent:-20.0pt'><span lang=EN-US style='line-height:
115%'>①<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='line-height:115%'>회사는 회원의 사전 동의 없이 회원의 개인정보를 제<span lang=EN-US>3</span>자에게
제공하지 않습니다<span lang=EN-US>. </span>다만<span lang=EN-US>, </span>관련 법령에 의하거나 이용자의
동의<span lang=EN-US>, </span>법률의 특별한 규정 등 </span><span style='line-height:115%'>「개인정보보호법」
제<span lang=EN-US>17</span>조 및 제<span lang=EN-US>18</span>조에 해당하는 경우에만 </span><span
style='line-height:115%'>&nbsp;다음과 같이 개인정보를 제<span lang=EN-US>3</span>자에게 제공하고 있습니다<span
lang=EN-US>.</span></span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0 width=551
 style='width:360pt;margin-left:40.0pt;border-collapse:collapse;border:none'>
 <tr style='height:1.0pt'>
  <td width=90 style='width:67.25pt;border-top:#999999;border-left:#999999;
  border-bottom:gray;border-right:gray;border-style:solid;border-width:1.0pt;
  background:#666666;padding:3.75pt 3.75pt 3.75pt 3.75pt;height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center'><b><span
  style='font-size:9.0pt;line-height:115%;color:white'>제공받는자</span></b></p>
  </td>
  <td width=109 style='width:82.1pt;border-top:solid #999999 1.0pt;border-left:
  none;border-bottom:solid #999999 1.0pt;border-right:solid gray 1.0pt;
  background:#666666;padding:0cm 0cm 0cm 0cm;height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center'><b><span
  style='font-size:9.0pt;line-height:115%;color:white'>제공목적</span></b></p>
  </td>
  <td width=113 style='width:3.0cm;border-top:solid #999999 1.0pt;border-left:
  none;border-bottom:solid gray 1.0pt;border-right:solid gray 1.0pt;background:
  #666666;padding:0cm 0cm 0cm 0cm;height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center'><b><span
  style='font-size:9.0pt;line-height:115%;color:white'>보유 및 이용기간</span></b></p>
  </td>
  <td width=238 style='width:178.7pt;border-top:solid #999999 1.0pt;border-left:
  none;border-bottom:solid gray 1.0pt;border-right:solid #999999 1.0pt;
  background:#666666;padding:0cm 0cm 0cm 0cm;height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center'><b><span
  style='font-size:9.0pt;line-height:115%;color:white'>제공하는 개인정보 항목</span></b></p>
  </td>
 </tr>
 <tr style='height:1.0pt'>
  <td width=90 style='width:67.25pt;border-top:none;border-left:solid #999999 1.0pt;
  border-bottom:solid #999999 1.0pt;border-right:solid gray 1.0pt;padding:3.75pt 3.75pt 3.75pt 3.75pt;
  height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center'><span
  style='line-height:115%'>회원이 매장<span lang=EN-US>(</span>기업<span lang=EN-US>) </span>인증을
  요청하는 각 사업체</span></p>
  </td>
  <td width=109 style='width:82.1pt;border-top:none;border-left:none;
  border-bottom:solid #999999 1.0pt;border-right:solid gray 1.0pt;padding:0cm 0cm 0cm 0cm;
  height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-autospace:ideograph-other;word-break:keep-all'><span style='line-height:
  115%'>인증자격 확인<span lang=EN-US>,</span></span></p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-autospace:ideograph-other;word-break:keep-all'><span style='line-height:
  115%'>매장<span lang=EN-US>(</span>기업<span lang=EN-US>) </span>인증</span></p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-autospace:ideograph-other;word-break:keep-all'><span style='line-height:
  115%'>인증 매장 내 주문내역 확인</span></p>
  </td>
  <td width=113 style='width:3.0cm;border-top:none;border-left:none;border-bottom:
  solid #999999 1.0pt;border-right:solid gray 1.0pt;padding:0cm 0cm 0cm 0cm;
  height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-autospace:ideograph-other;word-break:keep-all'><span style='line-height:
  115%'>회원 탈퇴 시</span></p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-autospace:ideograph-other;word-break:keep-all'><span style='line-height:
  115%'>인증 만료 시</span></p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-autospace:ideograph-other;word-break:keep-all'><span style='line-height:
  115%'>서비스 종료 시</span></p>
  </td>
  <td width=238 style='width:178.7pt;border-top:none;border-left:none;
  border-bottom:solid #999999 1.0pt;border-right:solid #999999 1.0pt;
  padding:0cm 0cm 0cm 0cm;height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-autospace:ideograph-other;word-break:keep-all'><span style='line-height:
  115%'>이메일<span lang=EN-US>, </span>닉네임<span lang=EN-US>, </span>매장정보<span
  lang=EN-US>, </span>주문정보<span lang=EN-US>, </span>서비스 이용기록<span lang=EN-US>, </span>접속로그<span
  lang=EN-US>(</span>접속일시<span lang=EN-US>), </span>결제정보<span lang=EN-US>, </span>이용정지
  기록<span lang=EN-US>, </span>접속<span lang=EN-US>IP</span>정보<span lang=EN-US>, </span>쿠키<span
  lang=EN-US>, </span>쿠폰 사용 정보<span lang=EN-US>, </span>멤버십 정보<span lang=EN-US>,
  </span>기기정보<span lang=EN-US>(</span>단말기<span lang=EN-US>/OS </span>종류<span
  lang=EN-US>, Device ID </span>등<span lang=EN-US>)</span></span></p>
  </td>
 </tr>
</table>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;text-indent:-20.0pt'><span lang=EN-US style='line-height:
115%'>②<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span>회원은
회사의 개인정보 제공 에 대한 동의를 거부할 권리가 있습니다<span lang=EN-US>. </span>다만<span lang=EN-US>,
</span>개인정보의제공에 대한 동의를 거부할 경우 서비스의 특성상 이용이 제한될 수 있습니다<span lang=EN-US>. </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;text-indent:-20.0pt'><span lang=EN-US style='line-height:
115%'>③<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='line-height:115%'>회사는 서비스 제공을 위하여 회원으로부터 수집한 개인정보를 다음과 같이 위탁하고 있으며<span
lang=EN-US>, </span>위탁계약 체결 시 </span><span style='line-height:115%'>「개인정보보호법」</span><span
style='line-height:115%'> 제<span lang=EN-US>26</span>조에 따라 위탁업무 수행목적 외 개인정보 처리 금지<span
lang=EN-US>, </span>기술적 및 관리적 보호조치<span lang=EN-US>, </span>재위탁 제한<span
lang=EN-US>, </span>수탁자에 대한 관리감독<span lang=EN-US>, </span>손해배상 등 책임에 관한 사항을 계약서
등 문서에 명시하고 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다<span lang=EN-US>. </span></span></p>

<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0 width=555
 style='width:360pt;margin-left:39.2pt;border-collapse:collapse;border:none'>
 <tr style='height:1.0pt'>
  <td width=142 style='width:106.3pt;border-top:#999999;border-left:#999999;
  border-bottom:gray;border-right:gray;border-style:solid;border-width:1.0pt;
  background:#666666;padding:3.75pt 3.75pt 3.75pt 3.75pt;height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'><b><span style='font-size:9.0pt;color:white'>수탁업체명</span></b></p>
  </td>
  <td width=274 style='width:205.55pt;border-top:solid #999999 1.0pt;
  border-left:none;border-bottom:solid gray 1.0pt;border-right:solid gray 1.0pt;
  background:#666666;padding:0cm 0cm 0cm 0cm;height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'><b><span style='font-size:9.0pt;color:white'>수탁 업무 내용 </span></b></p>
  </td>
  <td width=139 valign=top style='width:104.5pt;border-top:solid #999999 1.0pt;
  border-left:none;border-bottom:solid gray 1.0pt;border-right:solid #999999 1.0pt;
  background:#666666;padding:0cm 0cm 0cm 0cm;height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'><b><span style='font-size:9.0pt;color:white'>수탁기간</span></b></p>
  </td>
 </tr>
 <tr style='height:1.0pt'>
  <td width=142 style='width:106.3pt;border-top:none;border-left:solid #999999 1.0pt;
  border-bottom:solid gray 1.0pt;border-right:solid gray 1.0pt;padding:3.75pt 3.75pt 3.75pt 3.75pt;
  height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>㈜카카오페이</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>엔에이치엔페이코㈜</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>네이버파이낸셜<span lang=EN-US>(</span>주<span lang=EN-US>)</span></p>
  </td>
  <td width=274 style='width:205.55pt;border-top:none;border-left:none;
  border-bottom:solid gray 1.0pt;border-right:solid gray 1.0pt;padding:0cm 0cm 0cm 0cm;
  height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-indent:10.0pt;line-height:normal;text-autospace:ideograph-other;
  word-break:keep-all'>결제 대행 서비스 및 취소&#8729;환불 처리</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-indent:10.0pt;line-height:normal'>회원 온라인<span lang=EN-US>/</span>오프라인 거래내역
  조회</p>
  </td>
  <td width=139 rowspan=4 style='width:104.5pt;border-top:none;border-left:
  none;border-bottom:solid gray 1.0pt;border-right:solid #999999 1.0pt;
  padding:0cm 0cm 0cm 0cm;height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal;text-autospace:ideograph-other;word-break:keep-all'>회원 탈퇴 시</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal;text-autospace:ideograph-other;word-break:keep-all'>인증 만료 시</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal;text-autospace:ideograph-other;word-break:keep-all'>서비스 종료
  시</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal;text-autospace:ideograph-other;word-break:keep-all'>위탁계약 종료
  시</p>
  </td>
 </tr>
 <tr style='height:1.0pt'>
  <td width=142 style='width:106.3pt;border-top:none;border-left:solid #999999 1.0pt;
  border-bottom:solid gray 1.0pt;border-right:solid gray 1.0pt;padding:3.75pt 3.75pt 3.75pt 3.75pt;
  height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>㈜다날</p>
  </td>
  <td width=274 style='width:205.55pt;border-top:none;border-left:none;
  border-bottom:solid gray 1.0pt;border-right:solid gray 1.0pt;padding:0cm 0cm 0cm 0cm;
  height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-indent:10.0pt;line-height:normal;text-autospace:ideograph-other;
  word-break:keep-all'><span lang=EN-US>SMS</span>발송 서비스</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-indent:10.0pt;line-height:normal;text-autospace:ideograph-other;
  word-break:keep-all'>결제대행 서비스<span lang=EN-US>(</span>간편결제 서비스 포함<span
  lang=EN-US>) </span>및 취소&#8729;환불 처리</p>
  </td>
 </tr>
 <tr style='height:1.0pt'>
  <td width=142 style='width:106.3pt;border-top:none;border-left:solid #999999 1.0pt;
  border-bottom:solid gray 1.0pt;border-right:solid gray 1.0pt;padding:3.75pt 3.75pt 3.75pt 3.75pt;
  height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>㈜핀크럭스</p>
  </td>
  <td width=274 style='width:205.55pt;border-top:none;border-left:none;
  border-bottom:solid gray 1.0pt;border-right:solid gray 1.0pt;padding:0cm 0cm 0cm 0cm;
  height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-indent:10.0pt;line-height:normal;text-autospace:ideograph-other;
  word-break:keep-all'>포인트 적립 및 사용 내역 조회</p>
  </td>
 </tr>
 <tr style='height:1.0pt'>
  <td width=142 rowspan=2 style='width:106.3pt;border-top:none;border-left:
  solid #999999 1.0pt;border-bottom:solid gray 1.0pt;border-right:solid gray 1.0pt;
  padding:3.75pt 3.75pt 3.75pt 3.75pt;height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>㈜인포뱅크</p>
  </td>
  <td width=274 style='width:205.55pt;border-top:none;border-left:none;
  border-bottom:solid gray 1.0pt;border-right:solid gray 1.0pt;padding:0cm 0cm 0cm 0cm;
  height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-indent:10.0pt;line-height:normal;text-autospace:ideograph-other;
  word-break:keep-all'>회원 가입시 인증번호 발송</p>
  </td>
 </tr>
 <tr style='height:1.0pt'>
  <td width=274 style='width:205.55pt;border-top:none;border-left:none;
  border-bottom:solid gray 1.0pt;border-right:solid gray 1.0pt;padding:0cm 0cm 0cm 0cm;
  height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-indent:10.0pt;line-height:normal;text-autospace:ideograph-other;
  word-break:keep-all'>키오스크 주문 알림톡</p>
  </td>
  <td width=139 rowspan=2 style='width:104.5pt;border-top:none;border-left:
  none;border-bottom:solid #999999 1.0pt;border-right:solid #999999 1.0pt;
  padding:0cm 0cm 0cm 0cm;height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-indent:10.0pt;line-height:normal;text-autospace:ideograph-other;
  word-break:keep-all'>서비스 신청일로부터 <span lang=EN-US>7</span>일</p>
  </td>
 </tr>
 <tr style='height:1.0pt'>
  <td width=142 style='width:106.3pt;border-top:none;border-left:solid #999999 1.0pt;
  border-bottom:solid #999999 1.0pt;border-right:solid gray 1.0pt;padding:3.75pt 3.75pt 3.75pt 3.75pt;
  height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>㈜벤디스</p>
  </td>
  <td width=274 style='width:205.55pt;border-top:none;border-left:none;
  border-bottom:solid #999999 1.0pt;border-right:solid gray 1.0pt;padding:0cm 0cm 0cm 0cm;
  height:1.0pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  text-indent:10.0pt;line-height:normal;text-autospace:ideograph-other;
  word-break:keep-all'>식권대장 결제 <span lang=EN-US>push </span>알림</p>
  </td>
 </tr>
</table>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;text-indent:-20.0pt'><span lang=EN-US style='line-height:
115%'>④<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='line-height:115%'>회사는 위탁업무의 내용이나 수탁자가 변경될 경우 지체없이 본 개인정보 처리 방침을 통하여 공개하도록
하겠습니다<span lang=EN-US>. </span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;text-indent:-20.0pt'><span lang=EN-US style='line-height:
115%'>⑤<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='line-height:115%'>회사는 다음과 같이 재난<span lang=EN-US>, </span>감염병<span
lang=EN-US>, </span>급박한 생명</span><span style='line-height:115%'>·</span><span
style='line-height:115%'>신체 위험을 초래하는 사건</span><span style='line-height:115%'>·사고<span
lang=EN-US>, </span>급박한 재산 손실 등의 긴급상황이 발생하는 경우 정보주체의 동의 없이 관계기관에 개인정보를 제공할 수 있습니다<span
lang=EN-US>.</span></span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0 width=558
 style='width:360pt;margin-left:42.3pt;border-collapse:collapse;border:none'>
 <tr style='height:21.85pt'>
  <td width=85 style='width:63.8pt;border:solid windowtext 1.0pt;background:
  #666666;padding:0cm 5.4pt 0cm 5.4pt;height:21.85pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'><b><span style='font-size:9.0pt;color:white'>분류</span></b></p>
  </td>
  <td width=94 style='width:70.85pt;border:solid windowtext 1.0pt;border-left:
  none;background:#666666;padding:0cm 5.4pt 0cm 5.4pt;height:21.85pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'><b><span style='font-size:9.0pt;color:white'>근거법령</span></b></p>
  </td>
  <td width=104 style='width:77.95pt;border:solid windowtext 1.0pt;border-left:
  none;background:#666666;padding:0cm 5.4pt 0cm 5.4pt;height:21.85pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'><b><span style='font-size:9.0pt;color:white'>제공 기관</span></b></p>
  </td>
  <td width=274 style='width:205.55pt;border:solid windowtext 1.0pt;border-left:
  none;background:#666666;padding:0cm 5.4pt 0cm 5.4pt;height:21.85pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'><b><span style='font-size:9.0pt;color:white'>제공되는 개인정보</span></b></p>
  </td>
 </tr>
 <tr style='height:154.6pt'>
  <td width=85 style='width:63.8pt;border:solid windowtext 1.0pt;border-top:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:154.6pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>재난 대응</p>
  </td>
  <td width=94 style='width:70.85pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:154.6pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>재난안전법</p>
  </td>
  <td width=104 style='width:77.95pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:154.6pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>중앙대책본부</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>또는 지역대책본부</p>
  </td>
  <td width=274 style='width:205.55pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:154.6pt'>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.35pt;text-indent:-11.35pt;line-height:normal'><span
  lang=EN-US style='font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>
  </span></span>성명<span lang=EN-US>, </span>주민등록번호<span lang=EN-US>, </span>주소 및
  전화번호<span lang=EN-US>(</span>휴대전화번호 포함<span lang=EN-US>)</span></p>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.35pt;text-indent:-11.35pt;line-height:normal'><span
  lang=EN-US style='font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>
  </span></span>이동경로 파악 및 수색·구조를 위한 다음 정보</p>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:27.85pt;text-indent:-17.85pt;line-height:normal'><span
  lang=EN-US>가.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
  lang=EN-US>CCTV</span>를 통해 수집된 정보</p>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:27.85pt;text-indent:-17.85pt;line-height:normal'><span
  lang=EN-US>나.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span>교통카드의
  사용명세</p>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:27.85pt;text-indent:-17.85pt;line-height:normal'><span
  lang=EN-US>다.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span>신용카드·직불카드·선불카드의
  사용일시<span lang=EN-US>, </span>사용장소</p>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:27.85pt;text-indent:-17.85pt;line-height:normal'><span
  lang=EN-US>라.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span>처방전의
  의료기관 명칭<span lang=EN-US>. </span>전화번호 및 진료기록부상의 진료일시</p>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.35pt;text-indent:-11.35pt;line-height:normal'><span
  lang=EN-US style='font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>
  </span></span>개인위치정보</p>
  </td>
 </tr>
 <tr style='height:56.25pt'>
  <td width=85 style='width:63.8pt;border:solid windowtext 1.0pt;border-top:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:56.25pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>감염병의</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>예방</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>및 관리</p>
  </td>
  <td width=94 style='width:70.85pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:56.25pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>감염병</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>예방법</p>
  </td>
  <td width=104 style='width:77.95pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:56.25pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>질병관리청</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>또는</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>전국 시·도</p>
  </td>
  <td width=274 style='width:205.55pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:56.25pt'>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.35pt;text-indent:-11.35pt;line-height:normal'><span
  lang=EN-US style='font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>
  </span></span>성명<span lang=EN-US>, </span>주민등록번호<span lang=EN-US>, </span>주소 및
  전화번호<span lang=EN-US>(</span>휴대전화번호 포함<span lang=EN-US>)</span></p>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.35pt;text-indent:-11.35pt;line-height:normal'><span
  lang=EN-US style='font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>
  </span></span>「의료법」에 따른 처방전 및 진료기록부</p>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.35pt;text-indent:-11.35pt;line-height:normal'><span
  lang=EN-US style='font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>
  </span></span>질병관리청장이 정하는 기간의 출입국관리기록</p>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.35pt;text-indent:-11.35pt;line-height:normal'><span
  lang=EN-US style='font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>
  </span></span>그 밖에 이동경로를 파악하기 위한 다음 정보</p>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  10.0pt;margin-left:27.85pt;text-indent:-17.85pt;line-height:normal'><span
  lang=EN-US>가.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span>「여신전문금융업법」에
  따른 신용카드·직불카드·선불카드 사용명세</p>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  10.0pt;margin-left:27.85pt;text-indent:-17.85pt;line-height:normal'><span
  lang=EN-US>나.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span>「대중교통의
  육성 및 이용촉진에 관한 법률」에 따른 교통카드 사용명세</p>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  10.0pt;margin-left:27.85pt;text-indent:-17.85pt;line-height:normal'><span
  lang=EN-US>다.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span>「개인정보
  보호법」에 따른 영상정보처리기기를 통하여 수집된 영상정보</p>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.35pt;text-indent:-11.35pt;line-height:normal'><span
  lang=EN-US style='font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>
  </span></span>개인위치정보</p>
  </td>
 </tr>
 <tr style='height:55.8pt'>
  <td width=85 style='width:63.8pt;border:solid windowtext 1.0pt;border-top:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:55.8pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>가축의</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>전염성질병</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>발생·확산</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>방지</p>
  </td>
  <td width=94 style='width:70.85pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:55.8pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>가축전염병</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>예방법</p>
  </td>
  <td width=104 style='width:77.95pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:55.8pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>농림축산식품부</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>국립가축방역기관</p>
  </td>
  <td width=274 style='width:205.55pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:55.8pt'>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.35pt;text-indent:-11.35pt;line-height:normal'><span
  lang=EN-US style='font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>
  </span></span>고속도로 통행정보</p>
  </td>
 </tr>
 <tr style='height:49.45pt'>
  <td width=85 style='width:63.8pt;border:solid windowtext 1.0pt;border-top:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:49.45pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>실종아동·정신장애인·치매환자 등 발견·</p>
  </td>
  <td width=94 style='width:70.85pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:49.45pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>실종아동법</p>
  </td>
  <td width=104 style='width:77.95pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:49.45pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>경찰관서</p>
  </td>
  <td width=274 style='width:205.55pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:49.45pt'>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.35pt;text-indent:-11.35pt;line-height:normal'><span
  lang=EN-US style='font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>
  </span></span>개인위치정보<span lang=EN-US>, </span>인터넷주소<span lang=EN-US>, </span>통신사실확인자료</p>
  </td>
 </tr>
 <tr style='height:35.25pt'>
  <td width=85 style='width:63.8pt;border:solid windowtext 1.0pt;border-top:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:35.25pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>긴급 구조요청</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>등 대응</p>
  </td>
  <td width=94 style='width:70.85pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:35.25pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>위치정보법</p>
  </td>
  <td width=104 style='width:77.95pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:35.25pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>소방관서</p>
  </td>
  <td width=274 style='width:205.55pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:35.25pt'>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.35pt;text-indent:-11.35pt;line-height:normal'><span
  lang=EN-US style='font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>
  </span></span>개인위치정보</p>
  </td>
 </tr>
 <tr style='height:42.1pt'>
  <td width=85 style='width:63.8pt;border:solid windowtext 1.0pt;border-top:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:42.1pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>생계곤란 등의</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>위기상황 대처</p>
  </td>
  <td width=94 style='width:70.85pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:42.1pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>긴급복지</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>지원법</p>
  </td>
  <td width=104 style='width:77.95pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:42.1pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>국가 및</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>지방자치단체</p>
  </td>
  <td width=274 style='width:205.55pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:42.1pt'>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.35pt;text-indent:-11.35pt;line-height:normal'><span
  lang=EN-US style='font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>
  </span></span>생활이 곤란한 경우 등에 대한 긴급한 생계지원 대상 발굴에 필요한 최소한의 정보</p>
  </td>
 </tr>
 <tr style='height:63.3pt'>
  <td width=85 style='width:63.8pt;border:solid windowtext 1.0pt;border-top:
  none;padding:0cm 5.4pt 0cm 5.4pt;height:63.3pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>납치<span lang=EN-US>, </span>감금 등</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>범죄와 관련된 자의 개인정보 처리</p>
  </td>
  <td width=94 style='width:70.85pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:63.3pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>개인정보</p>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>보호법</p>
  </td>
  <td width=104 style='width:77.95pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:63.3pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal'>경찰관서</p>
  </td>
  <td width=274 style='width:205.55pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:63.3pt'>
  <p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.35pt;text-indent:-11.35pt;line-height:normal'><span
  lang=EN-US style='font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>
  </span></span><span lang=EN-US>CCTV </span>등 영상정보</p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-bottom:0cm'><b><span lang=EN-US
style='line-height:115%'>&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;text-indent:-18.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='line-height:115%;color:black'>개인정보의 파기절차 및 방법</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
background:white;text-autospace:ideograph-numeric ideograph-other;word-break:
keep-all'><span lang=EN-US style='line-height:115%'>①<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>회사는 개인정보 보유기간의 경과<span
lang=EN-US>, </span>처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:38.0pt;text-indent:-18.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>②<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>회원으로부터 동의 받은 개인정보 보유기간이
경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스<span
lang=EN-US>(DB)</span>로 옮기거나 보관장소를 달리하여 보존합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
background:white;text-autospace:ideograph-numeric ideograph-other;word-break:
keep-all'><span lang=EN-US style='line-height:115%'>③<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>개인정보 파기의 절차 및 방법은 다음과 같습니다<span
lang=EN-US>. </span></span></p>

<p class=MsoListParagraph style='margin-left:54.0pt;text-indent:-18.0pt;
background:white;text-autospace:ideograph-numeric ideograph-other;word-break:
keep-all'><span lang=EN-US style='line-height:115%'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>파기절차 </span></p>

<p class=MsoListParagraph style='margin-left:54.0pt;background:white;
text-autospace:ideograph-numeric ideograph-other;word-break:keep-all'><span
style='line-height:115%;color:black'>회사는 파기 사유가 발생한 개인정보를 선정하고 회원의 개인정보 보호책임자의 승인을
받아 개인정보를 파기합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:54.0pt;text-indent:-18.0pt;
background:white;text-autospace:ideograph-numeric ideograph-other;word-break:
keep-all'><span lang=EN-US style='line-height:115%'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>파기방법 </span></p>

<p class=MsoListParagraph style='margin-left:54.0pt;background:white;
text-autospace:ideograph-numeric ideograph-other;word-break:keep-all'><span
style='line-height:115%;color:black'>회사는 전자적 파일 형태로 기록 및 저장된 개인정보는 기록을 재생할 수 없도록
파기하며<span lang=EN-US>, </span>종이 문서에 기록 및 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다<span
lang=EN-US>. </span></span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-indent:-18.0pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span lang=EN-US style='line-height:115%'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
style='line-height:115%;color:black'>개인위치정보의 처리</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt'><span style='color:black'>회사는
</span><span style='color:black'>「</span><span style='color:black'>위치정보의 보호 및 이용
등에 관한 법률</span><span style='color:black'>」</span><span style='color:black'>에 따라
회원의 개인위치정보를 안전하게 관리합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>①<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>회사는 위치기반서비스 이용약관 제<span
lang=EN-US>4</span>조에 따른 서비스의 제공을 위해 </span><span style='color:black'>회원 가입일로부터
서비스를 제공하는 기간 동안에 한하여 회원의 </span><span style='line-height:115%;color:black'>개인위치정보를
보유 및 이용할 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>②<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black'>회사는 개인위치정보 이용 목적의 달성<span lang=EN-US>, </span>회원의
탈퇴 등 개인위치정보 처리 목적이 달성된 때에는 개인정보의 파기절차 및 방법에 따라 아래 </span><span lang=EN-US
style='color:black'>③</span><span style='color:black'>항의 위치정보 수집</span><span
style='color:black'>·</span><span style='color:black'>이용</span><span
style='color:black'>·</span><span style='color:black'>제공사실 확인자료를 제외한 회원의 개인위치정보를
지체없이 파기합니다<span lang=EN-US>. </span>그러나 내부방침 및 관계법령의 규정에 의하여 보존할 필요가 있는 경우에는 일정한
기간 동안 회원의 개인위치정보를 보관합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>③<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black'>회사는 「</span><span style='color:black'>위치정보의
보호 및 이용 등에 관한 법률</span><span style='color:black'>」</span><span
style='color:black'> 제<span lang=EN-US>16</span>조 제<span lang=EN-US>2</span>항에 따라
위치정보시스템에 자동으로 기록된 회원의 위치정보 수집ㆍ이용ㆍ제공사실 확인자료를 회원의 서비스 이용 탈퇴 시까지 보관합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>④<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>회사는 회원의 사전 동의 없이 개인위치정보를
제<span lang=EN-US>3</span>자에게 제공하지 않으며 회원이 지정하는 제<span lang=EN-US>3</span>자에게 개인위치정보를
제공하는 경우 매회 회원에게 제공받는 자<span lang=EN-US>, </span>제공일시 및 제공목적을 즉시 통보합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>⑤<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black'>「위치정보의 보호 및 이용 등에 관한 법률」 제<span
lang=EN-US>26</span>조제<span lang=EN-US>1</span>항에 따라 </span><span
style='line-height:115%;color:black'>아래의 경우에 해당하는 회원<span lang=EN-US>(</span>이하<span
lang=EN-US> “8</span>세 이하의 아동 등<span lang=EN-US>”</span>이라 함<span lang=EN-US>)</span>의
보호의무자가<span lang=EN-US> 8</span>세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는
경우에는 본인의 동의가 있는 것으로 봅니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:54.0pt;text-indent:-18.0pt;
background:white;text-autospace:ideograph-numeric ideograph-other;word-break:
keep-all'><span lang=EN-US style='line-height:115%'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='line-height:115%;color:black'>8</span><span
style='line-height:115%;color:black'>세 이하의 아동</span></p>

<p class=MsoListParagraph style='margin-left:54.0pt;text-indent:-18.0pt'><span
lang=EN-US style='line-height:115%'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%'>피성년후견인</span></p>

<p class=MsoListParagraph style='margin-left:54.0pt;text-indent:-18.0pt'><span
lang=EN-US style='line-height:115%'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%'>장애인복지법 제<span lang=EN-US>2</span>조제<span
lang=EN-US>2</span>항제<span lang=EN-US>2</span>호에 따른 정신적 장애를 가진 사람으로서 장애인고용촉진 및 직업재활법
제<span lang=EN-US>2</span>조제<span lang=EN-US>2</span>호에 따른 중증장애인에 해당하는 사람<span
lang=EN-US>(</span>장애인복지법 제<span lang=EN-US>32</span>조에 따라 장애인 등록을 한 사람만 해당한다<span
lang=EN-US>)</span></span></p>

<p class=MsoNormal style='margin-left:36.0pt'><span style='line-height:115%;
color:black'>위 권리를 행사하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 하며<span
lang=EN-US>, </span>이 경우 보호의무자는 위치기반서비스 이용약관에 따른 회원의 권리를 모두 가집니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal style='margin-left:36.0pt'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt'><span
lang=EN-US style='line-height:115%;color:black'>⑥<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>위치정보관리책임자의 성명과 연락처는 아래와
같습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal><span lang=EN-US style='line-height:115%;color:black'>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
style='line-height:115%;color:black'>성 명<span lang=EN-US>: </span>지성원</span></p>

<p class=MsoNormal><span lang=EN-US style='line-height:115%;color:black'>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
style='line-height:115%;color:black'>직 위<span lang=EN-US>: </span>대표이사</span></p>

<p class=MsoNormal><span lang=EN-US style='line-height:115%;color:black'>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
style='line-height:115%;color:black'>연락처<span lang=EN-US>: 1661-1167</span></span></p>

<p class=MsoNormal><span lang=EN-US style='line-height:115%;color:black'>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
style='line-height:115%;color:black'>이메일<span lang=EN-US>: </span></span><span
lang=EN-US><a href="mailto:beat@beatcorp.io"><span style='line-height:115%'>beat@beatcorp.io</span></a></span></p>

<p class=MsoNormal><span style='line-height:115%;color:black'>그 외 개인위치정보 처리와 관련된
자세한 내용은 위치기반서비스 이용약관을 참고하시기 바랍니다<span lang=EN-US>. </span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;text-indent:-18.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><b><span lang=EN-US
style='line-height:115%'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style='line-height:115%;color:black'>미이용자의 개인정보 파기 등에
관한 조치</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:27.1pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>①<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black'>회사는<span lang=EN-US> 1</span>년<span
lang=EN-US>(365</span>일<span lang=EN-US>) </span>동안 <span lang=EN-US>‘</span>앱<span
lang=EN-US>’</span>에 로그인하지 않은 회원의 계정은 휴면계정으로 전환하고<span lang=EN-US>, </span>해당 회원의
개인정보는 별도로 분리하여 보관합니다<span lang=EN-US>. </span>분리</span><span lang=EN-US
style='color:black'>·</span><span style='color:black'>보관된 개인정보는 <span
lang=EN-US>5</span>년간 보관 후 지체없이 파기되며 회원은 탈퇴처리 됩니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:27.1pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>②<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black'>회사는 휴면 전환<span lang=EN-US> 30</span>일 전
휴면 예정 회원에게 휴면 상태 전환 예정임을 카카오 알림톡 혹은 문자 등 회원에게 통지 가능한 방법으로 안내합니다<span
lang=EN-US>. </span>단<span lang=EN-US>, </span>해당 통지 수단에 대한 정보가 부재 또는 오류인 경우에는 홈페이지
및 앱 공지사항으로 대체됩니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:27.1pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>③<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black'>휴면계정으로 전환을 원하지 않으시는 경우<span lang=EN-US>,
</span>휴면계정 전환 전 로그인을 하시면 됩니다<span lang=EN-US>. </span>또한<span lang=EN-US>, </span>휴면계정으로
전환되었더라도 회원의 동의에 따라 앱을 통해 휴면 회원 해제 절차를 거쳐 정상적인 서비스를 이용할 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-indent:-18.0pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='line-height:115%'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
style='line-height:115%;color:black'>회원 및 법정대리인의 권리 및 그 행사 방법</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:27.1pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>④<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black'>회원 및 법정대리인은 </span><span
style='line-height:115%;color:black'>「개인정보보호법 시행령」 제<span lang=EN-US>41</span>조
제<span lang=EN-US>1</span>항에 따라 </span><span style='color:black'>언제든지 본인의 개인정보의
열람<span lang=EN-US>, </span>정정<span lang=EN-US>, </span>삭제<span lang=EN-US>, </span>처리정지
요구 등의 권리를 행사할 수 있고<span lang=EN-US>, </span>이 에 대해 회사는 지체없이 필요한 조치를 취합니다<span
lang=EN-US>. </span>또한 회사는 오류정정 요구가 있을 경우 그 정정 때까지 당해 개인정보를 이용하지 않으며<span
lang=EN-US>, </span>폐기 요구가 있을 경우에는 즉각 폐기하여 어떠한 용도로도 사용할 수 없도록 합니다<span
lang=EN-US>. </span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:27.1pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>⑤<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black'>회원 및 법정대리인은 개인정보 열람 및 오류정정을 위해 회사의 개인정보
관리책임자에게 서면<span lang=EN-US>, </span>전화 또는 이메일로 연락하거나 회사 방문을 통하여 요청할 수 있습니다<span
lang=EN-US>. </span>회사는 이에 대해 지체없이 조치하도록 하겠습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:27.1pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>⑥<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black'>제<span lang=EN-US>1</span>항에 따른 권리 행사는 회원의
법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다<span lang=EN-US>. </span>이 경우 <span
lang=EN-US>“</span>개인정보 처리 방법에 관한 고시<span lang=EN-US>(</span>제<span lang=EN-US>2020-7</span>호<span
lang=EN-US>)” </span>별지 제<span lang=EN-US>11</span>호 서식에 따른 위임장을 제출하셔야 합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:27.1pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>⑦<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black'>개인정보 열람 및 처리정지 요구는 </span><span
style='line-height:115%;color:black'>「개인정보보호법」 제<span lang=EN-US>35</span>조 제<span
lang=EN-US>4</span>항<span lang=EN-US>, </span>제<span lang=EN-US>37</span>조<span
lang=EN-US> 2</span>항에 의하여 정보주체의 권리가 제한될 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:27.1pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>⑧<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black'>개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로
명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:27.1pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>⑨<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black'>회사는 회원의 권리에 따른 열람의 요구<span lang=EN-US>,
</span>정정 및 삭제의 요구<span lang=EN-US>, </span>처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를
확인합니다<span lang=EN-US>. </span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:27.1pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>⑩<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='color:black'>회사는 회원 또는 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는
회사가 수집하는 개인정보의 보유 및 이용기간에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:38.0pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;text-indent:-18.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='line-height:115%;color:black'>개인정보 자동 수집 장치의 설치<span
lang=EN-US>/</span>운영 및 거부에 관한 사항</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span style='line-height:
115%;color:black'>회사는 회원들이 사용한 서비스와 웹 사이트들에 대한 방문 및 이용 형태<span lang=EN-US>, </span>회원
규모 등을 파악하여 회원에게 최적화된 정보 제공을 위하여 쿠키<span lang=EN-US>(cookie)</span>를 수집<span
lang=EN-US>, </span>사용합니다<span lang=EN-US>. </span>회원은 쿠키 설정에 대한 선택권을 가지고 있으며<span
lang=EN-US>, </span>브라우저 및<span lang=EN-US> OS</span>에서 제공하는 옵션에서 쿠키의 저장을 허용하거나
거부할 수 있습니다<span lang=EN-US>. </span>다만<span lang=EN-US>, </span>쿠키의 저장을 거부할 경우에는
로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%;font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>쿠키 설정 방법의 예</span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
background:white;text-autospace:ideograph-numeric ideograph-other;word-break:
keep-all'><span lang=EN-US style='line-height:115%'>①<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='line-height:115%;color:black'>Microsoft
Edge Internet Explorer </span><span style='line-height:115%;color:black'>웹 브라우저
오른쪽 상단<span lang=EN-US> [</span>도구설정<span lang=EN-US>] </span>메뉴 선택<span
lang=EN-US> → [</span>인터넷 옵션쿠키 및 사이트 권한<span lang=EN-US>] </span>선택<span
lang=EN-US> → [</span>쿠키 및 사이트 데이터 관리 및 삭제개인정보<span lang=EN-US>] </span>선택<span
lang=EN-US> → [</span>타사 쿠키 차단개인정보취급 수준<span lang=EN-US>] </span>체크박스 활성화 설정</span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
background:white;text-autospace:ideograph-numeric ideograph-other;word-break:
keep-all'><span lang=EN-US style='line-height:115%'>②<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='line-height:115%;color:black'>Chrome </span><span
style='line-height:115%;color:black'>웹 브라우저 우측의<span lang=EN-US> [</span>설정<span
lang=EN-US>] </span>메뉴 선택<span lang=EN-US> → [</span>개인정보 및 보안<span lang=EN-US>]
</span>선택<span lang=EN-US> → [</span>쿠키 및 기타 사이트 데이터<span lang=EN-US>] </span>선택<span
lang=EN-US> → [</span>일반 설정<span lang=EN-US>]</span>에서<span lang=EN-US> [</span>타사
쿠키 차단<span lang=EN-US>] </span>또는<span lang=EN-US> [</span>모든 쿠키 차단<span
lang=EN-US>] </span>선택화면 하단의 고급설정 표시<span lang=EN-US> → </span>개인정보의 콘텐츠 설정 버튼<span
lang=EN-US> → </span>쿠키</span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
background:white;text-autospace:ideograph-numeric ideograph-other;word-break:
keep-all'><span lang=EN-US style='line-height:115%'>③<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>안드로이드<span lang=EN-US>(</span>기본
브라우저 기준<span lang=EN-US>) </span>인터넷 아이콘 접속<span lang=EN-US> → </span>메뉴<span
lang=EN-US> → '</span>설정<span lang=EN-US>’ → ‘</span>개인정보보호 보안<span lang=EN-US>’
→ ‘</span>쿠키 허용<span lang=EN-US>’ </span>체크박스 해제</span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
background:white;text-autospace:ideograph-numeric ideograph-other;word-break:
keep-all'><span lang=EN-US style='line-height:115%'>④<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='line-height:115%;color:black'>IOS(</span><span
style='line-height:115%;color:black'>사파리 브라우저 기준<span lang=EN-US>) </span>설정 앱 아이콘
선택<span lang=EN-US> → Safari </span>아이콘 선택<span lang=EN-US> → ‘</span>개인정보보호 및 보안<span
lang=EN-US>’ → ‘</span>쿠키 차단<span lang=EN-US>’ </span>선택<span lang=EN-US> → ‘</span>항상
허용<span lang=EN-US>’ </span>설정</span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;text-indent:-18.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span
style='line-height:115%;color:black'>개인정보의 기술적<span lang=EN-US>/</span>관리적 및 물리적
보호 대책</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span style='line-height:
115%;color:black'>회사는 회원들의 개인정보의 안전을 위해 개인정보가 분실<span lang=EN-US>, </span>도난<span
lang=EN-US>, </span>누출<span lang=EN-US>, </span>변조 또는 훼손되지 않도록 안전성 확보를 위하여 아래의 기술적<span
lang=EN-US>/</span>관리적 및 물리적 대책을 강구하고 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:38.0pt;text-indent:-18.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>관리적 조치<span
lang=EN-US>: </span>내부관리계획의 수립 및 시행<span lang=EN-US>, </span>정기적인 직원 교육 등 </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:38.0pt;text-indent:-18.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>기술적 조치<span
lang=EN-US>: </span>개인정보시스템 등의 접근권한 관리<span lang=EN-US>, </span>접근통제시스템설치<span
lang=EN-US>, </span>고유식별정보의 암호화<span lang=EN-US>, </span>보안프로그램 설치<span
lang=EN-US>, </span>백신 프로그램의 주기적인 업데이트 등</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:38.0pt;text-indent:-18.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>물리적 조치<span
lang=EN-US>: </span>전산실<span lang=EN-US>, </span>자료보관실 등의 접근통제 등</span></p>

<p class=MsoNormal style='background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='line-height:115%;color:black'>회사는 회원의 실수 혹은 인터넷의
본질적인 위험성으로 인하여 야기되는 개인정보유출에 대해 책임을 지지 않습니다<span lang=EN-US>. </span>회원 본인의 개인정보를
보호하기 위해서 자신의 아이디와 비밀번호를 적절하게 관리하고<span lang=EN-US>, </span>그에 대한 책임을 져야 합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;text-indent:-18.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><b><span
style='line-height:115%;color:black'>개인정보 침해 관련 상담 및 신고</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:18.0pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span style='line-height:
115%;color:black'>본 개인정보 처리방침에 관한 민원이 있을 경우<span lang=EN-US>, </span>개인정보관리 담당부서에
문의하실 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%;font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>개인정보보호 및 관리 책임자</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>성 명<span lang=EN-US>: </span>지성원</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>직 위<span lang=EN-US>: </span>대표이사</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>연락처<span lang=EN-US>:
1661-1167</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>이메일<span lang=EN-US>: </span></span><span
lang=EN-US style='color:black'>beat@beatcorp.</span><span lang=EN-US
style='line-height:115%;color:black'>io</span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:40.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%;font-family:Wingdings'>&#159;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>고객서비스 담당 부서 </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>부서명<span lang=EN-US> :
</span>비트관리팀</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>연락처<span lang=EN-US> :
1661-1167</span></span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span style='line-height:
115%;color:black'>회원은 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의<span lang=EN-US>, </span>불만처리<span
lang=EN-US>, </span>피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다<span
lang=EN-US>. </span>담당자 및 담당부서에서는 회원의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span style='line-height:
115%;color:black'>당사 이외의 공공기관에서 개인정보침해 상담을 원하실 경우 아래의 기관의 도움을 받으시기 바랍니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>개인정보분쟁조정위원회<span
lang=EN-US>(</span></span><span lang=EN-US style='color:black'><a
href="http://www.kopico.go.kr"><span style='line-height:115%'>www.kopico.go.kr</span></a></span><span
lang=EN-US style='line-height:115%;color:black'> / </span><span
style='line-height:115%;color:black'>국번없이<span lang=EN-US> 1833-6972)</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>개인정보침해신고센터<span
lang=EN-US>(privacy.kisa.or.kr / </span>국번없이<span lang=EN-US> 118)</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>대검찰청<span lang=EN-US>(</span></span><span
lang=EN-US style='color:black'><a href="http://www.spo.go.kr"><span
style='line-height:115%'>www.spo.go.kr</span></a></span><span lang=EN-US
style='line-height:115%;color:black'> / </span><span style='line-height:115%;
color:black'>국번없이<span lang=EN-US> 1301)</span></span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:60.0pt;text-indent:-20.0pt;background:white;text-autospace:
ideograph-numeric ideograph-other;word-break:keep-all'><span lang=EN-US
style='line-height:115%'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='line-height:115%;color:black'>경찰청<span lang=EN-US>(cyberbureau.police.go.kr
/ </span>국번없이<span lang=EN-US> 182)</span></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:40.0pt;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span lang=EN-US>(</span>부칙<span
lang=EN-US>) </span></p>

<p class=MsoNormal style='margin-bottom:0cm'>본 방침은 <span lang=EN-US>2022</span>년
<span lang=EN-US>08</span>월 <span lang=EN-US>11</span>일부터 시행합니다<span
lang=EN-US>.</span></p>

</div>

</body>

</html>
`;
