import React, { lazy, Suspense } from 'react';
import { createBrowserHistory } from 'history';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { STR_initstore } from '@bis/constants/constStrings';
import { URL_REALTIME_SALES, URL_STEP1, URL_STEP2, URL_STEP3 } from '@bis/constants/constUrls';
import { useSelector } from 'react-redux';
import AppWrapper from '@jumbo/components/AppWrapper';
import Error404 from 'routes/Pages/404';
import ChangePasswordPage from './Auth/ChangePassword';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';

const AppRouter = () => {
  const history = createBrowserHistory();
  const { authUser } = useSelector(({ auth }) => auth);

  const indexUrl = `/${URL_STEP1}/${URL_REALTIME_SALES}/${STR_initstore}`;

  const LazyBis = lazy(() => import('routes/Pages/Bis'));
  const LazyManage = lazy(() => import('routes/Pages/Manage'));
  const LazyScms = lazy(() => import('routes/Pages/Scms'));
  const LazyLogin = lazy(() => import('routes/Auth/Login'));

  const router = createBrowserRouter([
    {
      path: '/',
      element: <AppWrapper />,
      errorElement: (
        <AppWrapper>
          <Error404 error />
        </AppWrapper>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={indexUrl} replace />,
        },
        {
          path: `/${URL_STEP1}/:menu/:storecode`,
          element: <LazyBis />,
        },
        {
          path: `/${URL_STEP2}/:menu/:storecode`,
          element: <LazyManage />,
        },
        {
          path: `/${URL_STEP3}/:menu/:storecode`,
          element: <LazyScms />,
        },
        {
          path: '/signin',
          element: authUser ? <Navigate to={indexUrl} /> : <LazyLogin />,
        },
        {
          path: '/init/:token',
          element: <ChangePasswordPage />,
        },
        {
          path: '/change/:token',
          element: <ChangePasswordPage />,
        },
      ],
    },
  ]);

  return (
    <Suspense fallback={<PageLoader />}>
      <RouterProvider router={router} history={history} />
    </Suspense>
  );
};

export default AppRouter;
