// export const COLOR_UP = '#8dcd03';

import theme from 'theme/theme';

// export const COLOR_DOWN = '#E00930';
export const COLOR_UP = theme.color.blue; // 2022-07-07 실시간 현황 V0.3에서 변경
export const COLOR_DOWN = theme.color.red; // 2022-07-07 실시간 현황 V0.3에서 변경

export const COLOR_STRING = '#0795f4';
export const COLOR_LIDAR_USE = '#2faf53';
export const COLOR_LIDAR_UNUSE = '#ee4a5d';
export const COLOR_STORE_SHOW = '#2faf53';
export const COLOR_STORE_HIDE = '#ee4a5d';

export const COLOR_OPERATIONS = {
  OPEN: theme.color.blue, // 정상운영 색
  CHECK: theme.color.green, // 점검중 색
  ABNORMALITY: theme.color.red, // 비정상 색
  CLOSE: theme.color.gray, // 운영종료 색
};

export const CHART_COLORS = [
  '#3c7ff4',
  '#0356af',
  '#0795f4',
  '#30009c',
  '#6200ee',
  '#00c4b4',
  '#ff8c00',
  '#ef5da8',
  '#8dcd03',
  '#9e95f7',
  '#0000ff',
  '#ba00ff',
  '#007900',
  '#7308a5',
  '#cc00af',
  '#ff4600',
  '#feb300',
  '#00ff00',
  '#ffff00',
  '#a8c17a',
  '#022569',
  '#aa3177',
  '#da53be',
  '#d69530',
  '#ff0000',
  '#2a12a0',
  '#d69530',
  '#9b2cbe',
  '#d3b158',
  '#858047',
  '#070fb2',
  '#e0e0e0',
];

export const TIMESTATUS_AVG_COLOR = 'rgba(128,128,128,1)';
export const TIMESTATUS_AVG_COLOR_GRADIENT = 'rgba(128,128,128,0)';
export const TIMESTATUS_COLORS = [
  '#8dcd03',
  '#0795f4',
  '#5600e8',
  '#ff8c00',
  '#ef5da8',
  '#d69530',
  '#9b2cbe',
  '#9e95f7',
  '#d3b158',
  '#858047',
  '#070fb2',
];

export const CHART_LINE_COLORS_BY_OPERATE_DAILY_SALES = [
  '#0795f4',
  '#ef5da8',
  '#5600e8',
  '#ff8c00',
  '#6fddd8',
  '#d69530',
  '#9b2cbe',
  '#9e95f7',
  '#d3b158',
  '#858047',
  '#070fb2',
];

export const CHART_BAR_COLORS_BY_OPERATE_REPORT_SALES_PROGRESS = ['#0795f4', '#6200ee', '#ef5da8'];

export const COLOR_LINES = [
  '#ff5757', // LINE1
  '#38bcd9', // LINE2
  '#8552f2', // LINE3
  '#3c7ff4', // LINE4
];

export const COLOR_LINES_ALL_REGISTER = [
  '#8552f2', // LINE1
  '#000000', // LINE2
];

// 실적현황; 개별매장; 시간대별 평균 판매 추이
export const COLOR_LINES_AVG_SALE_BY_TIME = [
  '#8552f2', // LINE1
  '#000000', // LINE2
];

export const COLOR_3C7FF4 = '#3c7ff4'; // LINE1

export const COLOR_BARS = [
  '#3c7ff4', // BAR1
  '#38bcd9', // BAR2
  '#8552f2', // BAR3
  '#ff5757', // BAR4
  '#666666', //BAR5
];
