import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CFN_intl } from 'utils/commFunction';
import styled from 'styled-components';
import theme from 'theme/theme';

const CmtDialog = ({
  open,
  setOpen,
  title,
  width,
  disabled,
  children,
  handleSubmitClick,
  type,
  submit,
  cancle = 'true',
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledDialog
      open={open}
      fullWidth={!width ? false : true}
      width={`${width}px`}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } }}>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <StyledDialogContent>{children}</StyledDialogContent>
      <StyledDialogActions>
        <StyledButton variant="contained" cancle={cancle.toString()} onClick={handleClose}>
          {type === 'confirm' ? CFN_intl('button.confirm') : CFN_intl('button.cancellation')}
        </StyledButton>
        {handleSubmitClick && (
          <StyledButton variant="contained" onClick={handleSubmitClick} disabled={disabled}>
            {submit || CFN_intl('button.register')}
          </StyledButton>
        )}
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default CmtDialog;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 0px;
    box-shadow: none;
    max-width: ${(props) => props.width};
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding: 24px 26px;
  font-size: 22px;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0px 26px 0px;
  white-space: pre-wrap;
`;

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: center;
  padding: 30px 0px 36px;
`;

const StyledButton = styled(Button)`
  width: 100px;
  height: 40px;
  background-color: ${(props) => (props.cancle === 'true' ? theme.color.softGray : '#000')};
  color: ${(props) => (props.cancle === 'true' ? theme.color.darkGray : '#fff')};
  box-shadow: none;

  &.Mui-selected,
  &.Mui-selected:hover,
  &:hover {
    background-color: ${(props) => (props.cancle ? '#c3c3c3' : '#5a5a5a')};
    box-shadow: none;
  }
`;
