import React from 'react';
import { Box, List } from '@mui/material';
import NavMenuItem from './NavMenuItem';
import NavCollapse from './NavCollapse';
import styled from 'styled-components';

const NavSection = (props) => {
  const { name, children = [] } = props;
  const isExpandable = children && children.length > 0;

  const MenuCollapse = <List component="div">{name}</List>;

  const MenuItemChildren = isExpandable ? (
    <List component="div" disablePadding>
      {children.map((item, index) => {
        switch (item.type) {
          case 'section':
            return <NavSection {...item} key={index} />;
          case 'collapse':
            return <NavCollapse {...item} key={index} />;
          default:
            return <NavMenuItem {...item} key={index} />;
        }
      })}
    </List>
  ) : null;

  return (
    <StyledNavSection>
      {MenuCollapse}
      {MenuItemChildren}
    </StyledNavSection>
  );
};

export default NavSection;

const StyledNavSection = styled(Box)`
  margin: 0px 16px;

  &:not(:last-child) {
    padding-bottom: 8px;
    border-bottom: 1px solid #f1f1f1;
  }
`;
