import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import styled from 'styled-components';

const color = {
  lightRed: '#fdf6f6',
  red: '#ff5757',
  lightBlue: '#f2f8fe',
  blue: '#3c7ff4',
  purple: '#ad8eee',
  darkPurple: '#8552f2',
  yellow: '#fda41f',
  orange: '#fd6f1f',
  green: '#14c6a4',
  mint: '#38BCD9',
  softGray: '#d9d9d9',
  gray: '#ccc',
  pureGray: '#888',
  darkGray: '#666',
  transparent: 'rgba(0, 0, 0, 0.0)',
};

const font = {
  family: 'NanumSquareOTF',
  xxl: '26px',
  xl: '22px',
  lg: '18px',
  md: '16px',
  sm: '14px',
  xs: '13px',
};

const theme = {
  font,
  color,
};

export default theme;

export const StyledValue = styled.span`
  font-size: ${(props) => props.fontsize || '22px'};
  font-weight: ${(props) => (props.bold ? 'bold' : 'regular')};
`;

export const StyledResponsiveContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1610px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledChartContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align || 'end'};
  max-width: 100%;
`;

export const CustomToggleButtonGroup = styled(ToggleButtonGroup)`
  padding-right: ${(props) => `${props.pr}px`};

  :last-child {
    padding-right: 0px;
  }
`;

export const CustomToggleButton = styled(ToggleButton)`
  && {
    padding: 0px 16px;
    height: 26px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 25px;
    font-family: 'NanumSquareOTF';
    font-size: 14px;
    font-weight: normal;
    color: #666;
    text-transform: none;

    &.Mui-selected,
    &.Mui-selected:hover,
    &:hover {
      background-color: #333;
      color: #fff;
      border: 1px solid #333;
      font-weight: bold;
    }

    &.Mui-disabled {
      background-color: #d9d9d9;
      color: #666;
      border: 1px solid #d9d9d9;
    }
  }
`;

export const LargeTitle = styled.h3`
  font-size: 26px;
`;

export const Title = styled.h4`
  font-size: 22px;
`;

export const MediumTitle = styled.h5`
  font-size: 18px;
`;

export const SubTitle = styled.h6`
  font-size: 16px;
  font-weight: normal;
`;

export const MediumTextBody = styled.p`
  font-size: 14px;
`;

export const SmallTextBody = styled.p`
  font-size: 13px;
`;
