import React from 'react';
import { Box, IconButton, MenuItem, MenuList, Popover, alpha } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { JWTAuth } from 'services';
import useClasses from 'hook/useClasses';
import CmtAvatar from '@coremat/CmtAvatar';
import clsx from 'clsx';

const styles = (theme) => ({
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
    iconRoot: {
      position: 'relative',
      color: alpha(theme.palette.common.white, 0.38),
      '&:hover, &.active': {
        color: theme.palette.common.white,
      },
    },
  },
});

const SidebarHeader = () => {
  const loginedUser = useSelector(({ auth }) => auth.authUser);
  const classes = useClasses(styles);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onLogoutClick = () => {
    handlePopoverClose();
    dispatch(JWTAuth.onLogout());
  };

  return (
    <Box pr={2}>
      <IconButton onClick={handlePopoverOpen} className={clsx(classes.iconRoot, 'Cmt-appIcon')} size="large">
        <CmtAvatar color="beat" size={40} variant="circular" alt={loginedUser && loginedUser.name} />
      </IconButton>

      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <MenuList style={{ width: 74, height: 29, padding: 0 }}>
            <MenuItem onClick={onLogoutClick} style={{ padding: 0 }}>
              <Box sx={{ width: 74, height: 29, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ fontSize: 15, fontWeight: 'bold' }}> Logout </span>
              </Box>
            </MenuItem>
          </MenuList>
        </Popover>
      )}
    </Box>
  );
};

export default SidebarHeader;
