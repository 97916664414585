import { STR_lidarToken } from '@bis/constants/constStrings';

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  else return null;
}

export default getCookie;

export const getCookieLidar = () => {
  return getCookie(STR_lidarToken);
};
