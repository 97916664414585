import { REFRESH_INIT_SEC } from '@bis/constants/constValues';
import {
  FETCH_VISIT_COUNT_TODAY,
  FETCH_VISIT_COUNT_LASTOPENDAY,
  FETCH_BEATBOX_LIST,
  FETCH_LAST_OPENDAY,
  FETCH_VISIT_COUNT_THISMONTH,
  FETCH_VISIT_COUNT_LASTOPENMONTH,
  FETCH_REFRESH_COUNTDOWN_MSEC,
  FETCH_LIST_TODAY_VISIT_BY_BEATBOX,
  FETCH_LIST_THISMONTH_VISIT_BY_BEATBOX,
  FETCH_LIST_PERIOD_VISIT_BY_BEATBOX,
  FETCH_VISIT_COUNT_PERIOD,
} from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  visitCntToday: 0,
  visitCntLastOpenDay: 0,
  beatboxlist: [],
  lastopenday: null,
  visitCntThisMonth: 0,
  visitCntLastOpenMonth: 0,
  countdownMillisecond: REFRESH_INIT_SEC * 1000,
  listTodayVisitByBeatbox: [],
  listThisMonthVisitByBeatbox: [],
  listPeriodVisitByBeatbox: [],
  visitCntPeriod: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_VISIT_COUNT_TODAY: {
      return { ...state, visitCntToday: action.payload };
    }
    case FETCH_VISIT_COUNT_LASTOPENDAY: {
      return { ...state, visitCntLastOpenDay: action.payload };
    }
    case FETCH_VISIT_COUNT_THISMONTH: {
      return { ...state, visitCntThisMonth: action.payload };
    }
    case FETCH_VISIT_COUNT_LASTOPENMONTH: {
      return { ...state, visitCntLastOpenMonth: action.payload };
    }
    case FETCH_BEATBOX_LIST: {
      return { ...state, beatboxlist: action.payload };
    }
    case FETCH_LAST_OPENDAY: {
      return { ...state, lastopenday: action.payload };
    }

    case FETCH_REFRESH_COUNTDOWN_MSEC: {
      return { ...state, countdownMillisecond: action.payload };
    }

    case FETCH_LIST_TODAY_VISIT_BY_BEATBOX: {
      return { ...state, listTodayVisitByBeatbox: action.payload };
    }
    case FETCH_LIST_THISMONTH_VISIT_BY_BEATBOX: {
      return { ...state, listThisMonthVisitByBeatbox: action.payload };
    }
    case FETCH_LIST_PERIOD_VISIT_BY_BEATBOX: {
      return { ...state, listPeriodVisitByBeatbox: action.payload };
    }
    case FETCH_VISIT_COUNT_PERIOD: {
      return { ...state, visitCntPeriod: action.payload };
    }
    default:
      return state;
  }
};
