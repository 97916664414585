import { apiCall } from 'services';

// ======================================================
// admin
export const test = () => apiCall('admin', 'get', {});

/* [실시간 현황] */

/**
 * 운영중 매장 수, 전체 매장 수 조회
 * 전체 매장 선택 시 호출(ALL, ALL_BEAT, ALL_BEATBOX)
 * @param {string} storeCode 매장코드
 * @returns
 */
export const apiStoreCount = (storeCode) => {
  return apiCall(`/store/${storeCode}/count`, 'get', {});
};

/**
 * [실시간 현황] 시간대별 현황 매장 목록
 * ALL, ALL_BEAT, ALL_BEATBOX
 * @param {string} allStoreCode
 * @returns
 */
export const apiStoreList = (allStoreCode) => {
  return apiCall(`/store/${allStoreCode}`, 'get', {});
};

/**
 * [운영 현황] 오늘 주문 잔수
 * @param {string} storeCode 매장코드
 * @returns
 */
export const apiStoreMonitoringCupcountToday = (storeCode) => {
  return apiCall(`/store/monitoring/cupcount/today/${storeCode}`, 'get', {});
};

/**
 * [운영 현황] 지난 28일 제조 현황
 * @param {string} storeCode 매장코드
 * @returns
 */
export const apiStoreMonitoringCupcountPeriod = (storeCode) => {
  return apiCall(`/store/monitoring/cupcount/period/${storeCode}`, 'get', {});
};

/**
 * [운영 현황] 장비 상태
 * @param {string} storeCode 매장코드
 * @returns
 */
export const apiStoreMonitoringEquipments = (storeCode) => {
  return apiCall(`/store/monitoring/equipments/${storeCode}`, 'get', {});
};

/**
 * [운영 현황] 원자재 현황
 * @param {string} storeCode 매장코드
 * @returns
 */
export const apiStoreMonitoringMaterialsUsage = (storeCode) => {
  return apiCall(`/store/monitoring/materials/usage/${storeCode}`, 'get', {});
};

/**
 * [운영 현황] 운영 상태
 * @param {string} storeCode 매장코드
 * @returns
 */
export const apiStoreMonitoringStatus = (storeCode) => {
  return apiCall(`/store/monitoring/status/${storeCode}`, 'get', {});
};

/**
 * [운영 현황] 전체 매장 운영 현황
 * @returns
 */
export const apiStoreMonitoringAllStatus = () => {
  return apiCall('/store/monitoring/all/status', 'get', {});
};
//
/**
 * [운영 현황] 매장 select box 목록
 * @returns
 */
export const apiStoreMonitoringList = () => {
  return apiCall('/store/monitoring/list', 'get', {});
};

// -------------- [운영 지표] --------------
/* [운영 지표] 상태 별 평균 조회 */
export const getIndicatorAvg = (storeCode, startDate, endDate) => {
  const params = {
    startDate,
    endDate,
  };
  return apiCall(`/store/indicator/avg/${storeCode}`, 'get', params);
};

/* [운영 지표] 가동 현황 */
export const getIndicatorOperate = (storeCode, startDate, endDate) => {
  const params = {
    startDate,
    endDate,
  };
  return apiCall(`/store/indicator/time/daily/${storeCode}`, 'get', params);
};

/* [운영 지표] 미가동 현황 */
export const getIndicatorNonOperate = (storeCode, startDate, endDate) => {
  const params = {
    startDate,
    endDate,
  };
  return apiCall(`/store/indicator/count/daily/${storeCode}`, 'get', params);
};

/* [운영 지표] 주문 현황 */
export const getIndicatorOrderStatus = (storeCode, startDate, endDate) => {
  const params = {
    startDate,
    endDate,
  };
  return apiCall(`/store/indicator/count/period/${storeCode}`, 'get', params);
};

/* [운영 지표] 상태 별 합 조회 */
export const getIndicatorSum = (storeCode, startDate, endDate) => {
  const params = {
    startDate,
    endDate,
  };
  return apiCall(`/store/indicator/sum/${storeCode}`, 'get', params);
};

/* [운영 지표] 매장 select box 목록 */
export const getIndicatorStoreList = () => {
  return apiCall(`/store/indicator/list`, 'get');
};
