import React, { useContext, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import LayoutContext from '@coremat/CmtLayouts/Vertical/LayoutContext/LayoutContext';
import SidebarHeaderToggleBtn from './toggleButton';
import styled from 'styled-components';

const SidebarHeader = ({ setSidebarHeaderHeight }) => {
  const { isOpen } = useContext(LayoutContext);
  const headerRef = useRef(null);
  const storeName = useSelector((state) => state.common.selectedStoreName);
  const padding = isOpen ? '52px 0px 42px' : '42px 0px 40px';

  useEffect(() => {
    const handleResize = () => {
      if (headerRef.current) {
        const newHeight = headerRef.current.offsetHeight;
        setSidebarHeaderHeight(newHeight);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen]);

  return (
    <StyledContainer padding={padding} ref={headerRef}>
      {isOpen ? (
        <img src={'/images/beatcorp/logo.svg'} alt="logo" />
      ) : (
        <img src={'/images/beatcorp/logo_s.svg'} alt="logo" />
      )}
      {isOpen && (
        <>
          <StyledStoreName>{storeName}</StyledStoreName>
          <SidebarHeaderToggleBtn />
        </>
      )}
    </StyledContainer>
  );
};

export default SidebarHeader;

const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${(props) => props.padding};
  border-bottom: 1px solid #f1f1f1;
  background-color: #fff;
`;

const StyledStoreName = styled.span`
  margin: 24px 0px 0px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
`;
