import React from 'react';
import { List } from '@mui/material';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';

const CmtVertical = ({ menuItems, height }) => {
  return (
    <List component="nav" disablePadding sx={{ width: '100%', maxHeight: height, overflow: 'auto' }}>
      {menuItems.map((item, index) => {
        switch (item.type) {
          case 'section':
            return <NavSection {...item} key={index} />;
          case 'collapse':
            return <NavCollapse {...item} key={index} />;
          case 'item':
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      })}
    </List>
  );
};

export default CmtVertical;
