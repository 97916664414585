import { CFN_intl } from 'utils/commFunction';

export const ENUM_TITLE_UNIT = {
  UNKNOWN: 0, // 알수 없음
  HUMAN: 1, // 명
  CURRENCY: 2, // 원
  CUPCOUNT: 3, // 잔
  PERCENT: 4, // %
  CUPANDPERSON: 5, // 잔/person
};

export const ENUM_TIME_RADIOVALUE = {
  UNKNOWN: 0, // 알수 없음
  SALES: 1, // 매출액
  CUPCOUNT: 2, // 판매잔 수
  VISIT: 3, // 방문자 수
};

export const ENUM_PERIOD_DATE = {
  UNKNOWN: 0, // 알수 없음
  LASTMONTH: 1, // 지난달
  THISMONTH: 2, // 이번달
  YESTERDAY: 3, // 어제
  CUSTOMDAY: 4, // 사용자 정의
  LAST7DAYS: 5, // 지난 7일
  LAST30DAYS: 6, //지난 30일
  LAST90DAYS: 7, //지난 90일
  LAST180DAYS: 8, //지난 180일
};

export const ENUM_EQUIPMENT_DATE = {
  CUSTOMDAY: 1, // 맞춤 설정
  YESTERDAY: 2, // 어제
  THISWEEK: 3, // 이번주
  LASTWEEK: 4, // 지난주
  THISMONTH: 5, // 이번달
  LASTMONTH: 6, // 지난달
  LAST7DAYS: 7, // 지난 7일
  LAST28DAYS: 8, // 지난 28일
  LAST30DAYS: 9, // 지난 30일
  LAST90DAYS: 10, // 지난 90일
};

export const ENUM_MONITORING_STORE_STATUS = {
  UNKNOWN: 0, // 알수 없음
  ABNORMALITY: 1, // 비정상
  CHECK: 2, // 점검중
  OPEN: 3, // 정상 운영
  CLOSE: 4, // 운영 종료
};

export const ENUM_SALES_VALUE_ERROR = {
  EMPTY: -200000,
  ECONNABORTED: -199999,
  DISABLESEARCH: -199998,
};

export const ENUM_STORE_TYPE = {
  UNKNOWN: 0, // 알수 없음
  ALL_STORE: 1, // 전체
  ALL_BEAT: 2, // 전체 비트
  ALL_BEATBOX: 3, // 전체 비트박스
  BEAT: 4, // 단독 비트
  BEATBOX: 5, // 단독 비트박스
};

export const ENUM_SELECT_STORE = {
  NONE: 0, // 스토어가 필요 없는 경우
  STORES_PLUS_ALL: 1, // all만 포함
  STORES: 2, // // all, allbeat, allbeatbox 제외
  STORES_LIDAR: 3, // all, allbeat, allbeatbox 제외 후 lidar floorid가 유효한 매장
  STORES_OPERATE: 4, // all을 포함하지만 운영중인(폐점하지 않은) 매장
};

export const ENUM_MANAGE_USER_ACTION = {
  UNKNOWN: 0,
  EDIT: 1,
  DISABLE: 2,
  ENABLE: 3,
  TOEMAIL: 4,
  DELETE: 5,
};

export const ENUM_MANAGE_STORE_FILTER_OPTION = {
  LIDAR_USE: true,
  LIDAR_UNUSE: false,
  STORE_SHOW: true,
  STORE_HIDE: false,
};

export const ENUM_MANAGE_USER_OPTION = {
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  SINGLESTORE: false,
  ALLSTORE: true,
};

export const ENUM_MANAGE_USER_FILTER_OPTION = {
  STATUS_ACTIVE: 'ACTIVE',
  STATUS_STOP: 'STOP',
  STATUS_WAIT: 'WAIT',
};

export const ENUM_REALTIME_TAB_INDEX = {
  TAB_INDEX_ZERO: 0, // 12칸 카드
  TAB_INDEX_ONE: 1, // 시간대별 차트
  TAB_INDEX_TWO: 2, // 분포도
  TAB_INDEX_THREE: 3,
};

export const ENUM_INDICATOR_TOOLTIP_TYPE = {
  RUNNING_STATUS: 1, // 가동 현황
  STOP_STATUS: 2, // 미가동 현황
  ORDER_STATUS: 3, // 주문 현황
};

export const ENUM_STORE_STATUS_BY_LIDAR = {
  STORE_ALL: 0, // 전체 매장
  STORE_USE_LIDAR: 1, // 라이다 적용 매장
  STORE_NOTUSE_LIDAR: 2, //라이다 미적용 매장
};

export const ENUM_APP_USER = {
  CONNECT_COUNT: 0, // App 접속자
  NEW_ORDER_COUNT: 1, // 신규 주문자
  RE_ORDER_COUNT: 2, //재 주문자
  ORDER_RATIO_COUNT: 3, //App 주문 비율
  NEW_REGISTER_COUNT: 4, // 신규 가입자
  ALL_REGISTER_COUNT: 5, // 전체 가입자
};

export const ENUM_PERIOD_OPTION = {
  PREV_PERIOD: 0, // 이전 기간
  PREV_YEAR: 1, // 전년 동기
  PREV_PERIOD_SAMEDAYS: 2, // 이전 기간(요일 일치)
  PREV_YEAR_SAMEDAYS: 3, // 전년 동기(요일 일치)
};

export const ENUM_WEEKDAY = {
  MON: 1,
  TUE: 2,
  WED: 3,
  THUR: 4,
  FRI: 5,
  SAT: 6,
  SUN: 7,
};

export const ENUM_WEEKDAY_WITH_ALL = {
  SUN: 0, //일요일
  MON: 1, //월요일
  TUE: 2, //화요일
  WED: 3, //수요일
  THUR: 4, //목요일
  FRI: 5, //금요일
  SAT: 6, //토요일
  ALL: 7, //전체
};

export const ENUM_WEEK_STR = {
  1: '월',
  2: '화',
  3: '수',
  4: '목',
  5: '금',
  6: '토',
  7: '일',
};

export const ENUM_CHART_TYPE = {
  AVG_SALECOUNT_BY_TIME: 100, // 실적현황; 개별매장; 시간대별 평균 판매 추이
};

export const ENUM_VISITOR_ERROR = {
  VISIT_NULL: -200000,
  VISIT_ZERO: -100000,
};

export const ENUM_PAGE = {
  BIS: 'bis',
  SCMS: 'scms',
};

export const ENUM_SCMS_USP_ERROR = new Map([
  [400, CFN_intl('error.message.invalidCouponFormat')],
  [404, CFN_intl('error.message.nonExistentData')],
  [410, CFN_intl('error.message.couponNotAvailable')],
  [500, CFN_intl('error.message.serverError')],
]);

export const ENUM_STORE_SUBSCRIPTION = new Map([
  ['BASIC', '기본'],
  ['PREMIUM', '고급'],
]);
