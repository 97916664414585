import { applyMiddleware, createStore } from 'redux';
import combinedReducer from '../reducers';
import thunk from 'redux-thunk';
import { createReduxHistory, routerMiddleware } from './history';

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const store = createStore(combinedReducer, {}, bindMiddleware([routerMiddleware, thunk]));

window.store = store;

export const history = createReduxHistory(store);
