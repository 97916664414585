import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import { componentColors } from '../CmtHelpers/JssHelper';

const colorOptions = [...componentColors, 'random', 'beat'];

const CmtAvatar = React.forwardRef(({ src, alt, className, color, size, phCharLength, children, ...rest }, ref) => {
  const getPlaceholderChar = (str) => {
    if (str && phCharLength > 0) {
      return str.substr(0, phCharLength).toUpperCase();
    }
  };

  return (
    <Avatar sx={{ width: size, height: size, fontSize: size / 2 }} ref={ref} src={src} alt={alt} {...rest}>
      {!src && !children && alt ? getPlaceholderChar(alt) : children}
    </Avatar>
  );
});

CmtAvatar.propTypes = {
  color: PropTypes.oneOf(colorOptions),
  phCharLength: PropTypes.number,
  size: PropTypes.oneOfType([PropTypes.oneOf(['small', 'medium', 'large']), PropTypes.number]),
};

CmtAvatar.defaultProps = {
  color: 'grey',
  phCharLength: 1,
  size: 'medium',
};

export default CmtAvatar;
