import React, { useContext, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { globalStyles } from 'theme/GlobalCss';
import AppContext from '../contextProvider/AppContextProvider/AppContext';
import AppLocale from '../../../i18n';
import AppLayout from '../AppLayout';
import PrivateRoute from 'routes/PrivateRoute';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import 'dayjs/locale/ko';

const AppWrapper = ({ children }) => {
  const { theme, locale, direction } = useContext(AppContext);
  const currentAppLocale = AppLocale[locale.locale];

  useEffect(() => {
    if (direction === 'rtl') {
      document.body.setAttribute('dir', 'rtl');
    } else {
      document.body.setAttribute('dir', 'ltr');
    }
  }, [direction]);

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <GlobalStyles styles={globalStyles(theme)} />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
            <CssBaseline />
            <AppLayout>{children ?? <PrivateRoute />}</AppLayout>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </IntlProvider>
  );
};

export default AppWrapper;
