/**
 * 이용약관 HTML
 */
export const agree_terms = `
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=ks_c_5601-1987">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:굴림;
	panose-1:2 11 6 0 0 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"맑은 고딕";
	panose-1:2 11 5 3 2 0 0 2 0 4;}
@font-face
	{font-family:"@맑은 고딕";}
@font-face
	{font-family:"@굴림";
	panose-1:2 11 6 0 0 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕";}
a:link, span.MsoHyperlink
	{color:#0563C1;
	text-decoration:underline;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	font-family:"맑은 고딕";}
.MsoChpDefault
	{font-family:"맑은 고딕";}
.MsoPapDefault
	{margin-bottom:8.0pt;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:3.0cm 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=KO link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=left style='text-align:left;line-height:normal;
text-autospace:ideograph-numeric ideograph-other;word-break:keep-all'><b><span
style='font-size:18.0pt;color:black'>이용약관</span></b></p>

<p class=MsoNormal align=left style='text-align:left;line-height:normal;
text-autospace:ideograph-numeric ideograph-other;word-break:keep-all'><b><span
lang=EN-US style='font-size:18.0pt;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal align=left style='text-align:left;line-height:normal;
text-autospace:ideograph-numeric ideograph-other;word-break:keep-all'><span
style='color:black'>제<span lang=EN-US> 1 </span>조<span lang=EN-US> (</span>목적<span
lang=EN-US>)</span></span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>본 약관은<span
lang=EN-US> b;eat CP Page </span>사용자<span lang=EN-US>(</span>이하 “이용자”<span
lang=EN-US>)</span>가 주식회사 비트코퍼레이션<span lang=EN-US>(</span>이하 “회사<span
lang=EN-US>&quot;)</span>이 제공하는<span lang=EN-US> b;eat CP Page(</span>이하 ‘사이트’<span
lang=EN-US>)</span>를 통하여 전용 소프트웨어 프로그램의 이용 및 관리를 함에 있어 이용 절차 및 필요한 사항을 규정하는데 그 목적이
있습니다<span lang=EN-US>.<br>
<br>
</span></span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 2 </span>조<span lang=EN-US> (</span>정의<span lang=EN-US>)</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='color:black'>&quot;</span><span style='color:black'>사이트<span
lang=EN-US>&quot;</span>란 “회사”가 인터넷을 통해 서비스를 제공하는 ‘<span lang=EN-US>b;eat CP
Page</span>’로써<span lang=EN-US>, </span>‘<span lang=EN-US>https://cp.beatcorp.io/’
</span>웹브라우저 화면에 표시되는 페이지를 말하며<span lang=EN-US>, </span>그에 부속된 웹페이지를 포함합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='color:black'>&quot;</span><span style='color:black'>서비스<span
lang=EN-US>&quot;</span>라 함은 “회사”가 제<span lang=EN-US>1</span>항의 정의에 의한 “사이트”에서 제공하는
모든 서비스를 말합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>③<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='color:black'>&quot;</span><span style='color:black'>이용자<span
lang=EN-US>&quot;</span>란 본 이용약관에 동의하고 “회사”가 정한 소정의 절차에 따라 이용자로 등록된 후 “사이트”에 접속하여
본 약관에 따라 “회사”가 제공하는 서비스를 이용하는 자를 말합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 3 </span>조<span lang=EN-US> (</span>약관의 명시 및 변경 등<span lang=EN-US>)</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”는 본 약관의 내용을 이용자가 알 수 있도록 “사이트”의 첫 화면 또는 첫 화면과의 연결화면에 게시합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”가 본 약관을 변경하고자 할 때에는 변경사유<span lang=EN-US>, </span>변경내용 및
적용일자 등을 명시하여 적용일자<span lang=EN-US> 7</span>일 이전부터 “사이트”에 게시합니다<span lang=EN-US>.
</span>다만<span lang=EN-US>, </span>법령의 개정이나 제도의 개선 등으로 긴급히 본 약관을 변경할 경우에는 즉시 이를
게시하고 통지할 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>③<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>제<span lang=EN-US>2</span>항의 변경 내용이 이용자에게 불리한 경우 “회사”는 변경 전
최소<span lang=EN-US> 30</span>일 전까지 “사이트”에 게시하거나 “이용자”가 사전에 “회사”에 제공한 전자우편주소로 개별
통지합니다<span lang=EN-US>. </span>다만<span lang=EN-US>, </span>기존 이용자에게 변경 전 내용이 그대로
적용되는 경우 또는 이용자가 변경내용에 대한 통지를 받지 아니하겠다는 의사를 명시적으로 표시한 경우에는 개별 통지를 하지 않습니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>④<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”는 제<span lang=EN-US>3</span>항의 통지를 할 경우<span
lang=EN-US> &quot;</span>이용자가 변경에 동의하지 아니한 경우 통지를 받은 날로부터<span lang=EN-US> 30</span>일
이내에 서비스 이용을 종료할 수 있으며<span lang=EN-US>, </span>서비스 이용 종료의 의사표시를 하지 아니한 경우에는 변경에
동의한 것으로 본다<span lang=EN-US>&quot;</span>라는 취지의 내용을 통지합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>⑤<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“이용자”가 제<span lang=EN-US>4</span>항의 통지를 받은 날로부터<span
lang=EN-US> 30</span>일 이내에 서비스 이용 종료의 의사표시를 하지 아니한 경우에는 약관 변경에 동의한 것으로 봅니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>⑥<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>본 약관에서 정하지 아니한 사항에 관하여는 관계법령 또는 일반 관례에 따릅니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 4 </span>조<span lang=EN-US> (</span>서비스의 제공 및 변경<span lang=EN-US>)</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”는 <span lang=EN-US>‘</span>운영 현황<span lang=EN-US>, </span>매출
및 방문자 통계<span lang=EN-US>, </span>수익 리포트 등 이용자 매장의 전반적인 현황을 제공하는 서비스<span
lang=EN-US>’</span>에 관한 서비스를 제공하며<span lang=EN-US>, </span>“회사” 사정에 따라 제공할 서비스의
내용을 변경하거나 일부 제한 할 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”는 제<span lang=EN-US>1</span>항에 따라 제공되는 정보를 “이용자”가 임의로 활용함에
따라 발생하는 손해에 대해서는 배상책임을 부담하지 않습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 5 </span>조<span lang=EN-US> (</span>서비스의 중단<span lang=EN-US>)</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”는 천재지변<span lang=EN-US>, </span>정전<span lang=EN-US>, </span>화재<span
lang=EN-US>, </span>통신설비의 보수&#8729;점검&#8729;교체 및 고장 등으로 인한 통신장애<span lang=EN-US>, </span>기타
불가항력적인 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>제<span lang=EN-US>1</span>항에 의한 서비스 중단의 경우에는 “회사”는 제<span
lang=EN-US>8</span>조에 정한 방법으로 “이용자”에게 통지합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>③<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”는 제<span lang=EN-US>1</span>항의 사유에 의한 서비스의 제공 중단으로 인하여 이용자에게
발생하는 문제에 대해서는 어떠한 책임도 부담하지 않습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 6 </span>조<span lang=EN-US> (</span>이용자 등록<span lang=EN-US>)</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“이용자”가 되고자 하는 자는 “회사”가 제공하는 소정의 절차에 따라 본 약관의 적용에 대한 동의의 의사표시를
하고 “사이트” 이용 신청을 하며<span lang=EN-US>, </span>“회사”가 이를 승낙함으로써 “이용자”가 됩니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>다음 각 호의<span lang=EN-US> 1</span>에 해당하는 자에 대하여는 승낙을 거절하거나 유보할
수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>본 약관 제<span lang=EN-US>7</span>조 제<span lang=EN-US>3</span>항에
의하여 이용 자격을 상실한 적이 있는 경우</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”에 제공하는 정보 등에 허위<span lang=EN-US>, </span>누락<span
lang=EN-US>, </span>오기 등이 있는 경우</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사” 정책상 이용자로 등록하는 것이 부적절한 것으로 판단되는 경우</span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>③<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='color:black'>“</span><span style='color:black'>이용자”는 제<span
lang=EN-US>1</span>항에서 “회사”에 제공한 정보가 변경되었을 경우에는 이를 “회사”에 지체없이 알려야 하며<span
lang=EN-US>, </span>정보의 미변경으로 인하여 발생되는 문제의 책임은 “이용자”에게 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 7 </span>조<span lang=EN-US> (</span>이용자 탈퇴 및 자격 상실 등<span
lang=EN-US>)</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“이용자”는 언제든지 “회사”에 탈퇴를 요청할 수 있으며 회사는 즉시 탈퇴를 처리합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“이용자”가 다음 각 호의 사유에 해당하는 경우<span lang=EN-US>, </span>“회사”는 “이용자”
자격을 제한 및 정지시킬 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“사이트” 이용 신청 시 허위 내용을 제공한 경우</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사” 및 다른 이용자의 사이트 운영&#8729;관리 및 이용을 방해하거나 그 정보를 도용하는 경우</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>관계법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</span></p>

<p class=MsoListParagraph style='margin-left:40.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>③<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”가 이용자 자격을 제한&#8729;정지시킨 후<span lang=EN-US>, </span>동일한 행위가<span
lang=EN-US> 2</span>회 이상 반복되거나<span lang=EN-US> 30</span>일 이내에 그 사유가 시정되지 아니하는 경우<span
lang=EN-US>, </span>“회사”는 이용자 자격을 상실시킬 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 8 </span>조<span lang=EN-US> (</span>이용자에 대한 통지<span lang=EN-US>)</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”가 “이용자”에 대한 통지를 하는 경우<span lang=EN-US>, </span>“이용자”가 “회사”에
제공한 전자우편주소로 할 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”는 다수 이용자에 대한 통지의 경우<span lang=EN-US> 1</span>주일 이상 사이트에
게시함으로써 개별 통지에 갈음할 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 9 </span>조<span lang=EN-US> (</span>개인정보보호<span lang=EN-US>)</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”는 “이용자”의 정보수집 시 원활한 서비스 제공을 위해 필요한 최소한의 정보를 수집합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”는 “이용자”의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>③<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“이용자”는 언제든지 “회사”가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정 또는 동의 철회를 요구할
수 있으며<span lang=EN-US>, </span>“회사”는 이에 대해 지체 없이 필요한 조치를 취합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>④<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>기타 개인정보 관련 사항은 “회사”의 개인정보 처리방침<span lang=EN-US>(</span>지침<span
lang=EN-US>)</span>에 의거합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 10 </span>조<span lang=EN-US> (</span>“회사”의 의무<span lang=EN-US>)</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며<span lang=EN-US>,
</span>본 약관이 정하는 바에 따라 “이용자”에게 서비스를 제공하는 데 최선을 다합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”는 “이용자”가 안전하게 “서비스”를 이용할 수 있도록 서비스 제공 및 보안과 관련된 설비에 대해 유지<span
lang=EN-US>, </span>점검 또는 복구 등의 조치를 성실히 이행합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 11 </span>조<span lang=EN-US> (“</span>이용자<span lang=EN-US>”</span>의
의무<span lang=EN-US>)</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“이용자”의<span lang=EN-US> ID, </span>비밀번호 관리에 대한 일체의 책임은 이용자 본인에게
있습니다<span lang=EN-US>. </span>“이용자”는 자신의<span lang=EN-US> ID, </span>비밀번호를 제<span
lang=EN-US>3</span>자에게 이용하게 해서는 안됩니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“이용자”는 자신의<span lang=EN-US> ID, </span>비밀번호를 도난 당하거나 제<span
lang=EN-US>3</span>자가 사용하고 있음을 인지한 경우에는 즉시 “회사”에 통보하여야 합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>③<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“이용자”는 본 약관 및 관계법령에서 규정한 사항과 서비스 이용안내 또는 주의사항을 성실히 준수하여야 합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>④<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“이용자”는 “회사”의 사전승인 없이 “서비스”를 이용하여<span lang=EN-US> b;eat </span>관리
외에 별도의 영업활동을 할 수 없으며<span lang=EN-US>, </span>그 영업활동으로 인하여 “회사” 및 제<span
lang=EN-US>3</span>자에게 손해가 발생하는 경우에는 이를 배상하여야 하고 “회사”는 그 영업활동 결과에 대하여 책임을 지지 아니합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>⑤<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“이용자”는 “서비스”를 이용할 때 다음 각 호의 행위를 하여서는 안됩니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”에 이용자 정보를 허위 제공하는 행위</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“사이트”에 게시된 정보를 무단으로 변경 또는 게시 목적 외 이용하는 행위</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사” 및 제<span lang=EN-US>3</span>자의 지적재산권 및 기타 법적 권리를 침해하거나
업무를 방해하는 행위</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>다른 이용자의<span lang=EN-US> ID, </span>비밀번호 및 기타 정보를 도용하는 행위</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>정크메일<span lang=EN-US>(junk mail), </span>스팸메일<span
lang=EN-US>(spam mail), </span>행운의 편지<span lang=EN-US>(chain letter), </span>기타
광고&#8729;홍보성 메일<span lang=EN-US>, </span>외설 또는 폭력적인 메시지<span lang=EN-US>, </span>화상<span
lang=EN-US>, </span>음성 등이 담긴 메일을 보내거나 기타 공서양속에 반하는 정보를 전송 또는 게시하는 행위</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보<span lang=EN-US>(</span>컴퓨터 프로그램
등<span lang=EN-US>)</span>의 전송 또는 게시하는 행위</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”의 임&#8729;직원<span lang=EN-US>(</span>위탁업체<span lang=EN-US>/</span>협력업체
임&#8729;직원 포함<span lang=EN-US>)</span>이나 “사이트”의 관리자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글<span
lang=EN-US>, </span>사진 등을 게시하거나 메일을 발송하는 행위</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>컴퓨터 소프트웨어<span lang=EN-US>, </span>하드웨어<span lang=EN-US>, </span>전기통신
장비의 정상적인 가동을 방해<span lang=EN-US>, </span>파괴할 목적으로 고안된 소프트웨어<span lang=EN-US>, </span>하드웨어<span
lang=EN-US>, </span>전기통신 장비의 정상적인 가동을 방해<span lang=EN-US>, </span>파괴할 목적으로 고안된 소프트웨어<span
lang=EN-US>, </span>바이러스<span lang=EN-US>, </span>기타 다른 컴퓨터 코드<span lang=EN-US>,
</span>파일<span lang=EN-US>, </span>프로그램을 포함하고 있는 자료를 게시하거나 전자우편으로 발송하는 행위</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>다른 이용자에 대한 개인정보를 그 동의 없이 수집<span lang=EN-US>, </span>처리<span
lang=EN-US>, </span>저장<span lang=EN-US>, </span>제공<span lang=EN-US>, </span>공개 등
하는 행위</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>10.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='color:black'>불특정 다수의 자를 대상으로 하여 광고 또는 선전을 게시하거나 스팸메일을 전송하는 등의 방법으로 사이트 내지
사이트의 서비스를 이용하여 영리목적의 활동을 하는 행위</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>11.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='color:black'>본 약관 및 기타 서비스 이용에 관한 규정을 위반하는 행위</span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>12.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='color:black'>“사이트”에 별도 게시한 이용 규정을 위반하는 행위</span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>⑥<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>본조 위반 이용자가 있을 경우<span lang=EN-US>, </span>“회사”는 본 약관 제<span
lang=EN-US>7</span>조 제<span lang=EN-US>2</span>항<span lang=EN-US>, </span>제<span
lang=EN-US>3</span>항에서 정한 바에 따라 해당 이용자의 이용 자격을 적절한 방법으로 제한 및 정지<span
lang=EN-US>, </span>상실시킬 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>⑦<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='color:black'>“</span><span style='color:black'>이용자”는 귀책사유로 인하여
“회사”나 다른 이용자가 손해를 입은 경우에는 이를 배상할 책임이 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 12 </span>조<span lang=EN-US> (</span>저작권의 귀속과 이용제한<span lang=EN-US>)</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”의 게시물<span lang=EN-US>(</span>저작물<span lang=EN-US>)</span>에
대한 저작권 기타 지적재산권은 “회사”에 귀속합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“이용자”는 “사이트”를 이용함으로써 얻은 정보를 “회사”의 사전 승낙 없이 복제<span
lang=EN-US>, </span>송신<span lang=EN-US>, </span>출판<span lang=EN-US>, </span>배포<span
lang=EN-US>, </span>방송 기타 방법에 의하여<span lang=EN-US> b;eat </span>관리 목적 외 영리목적으로 이용하거나
제<span lang=EN-US>3</span>자에게 이용하게 하여서는 안됩니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>③<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“이용자”의 게시물<span lang=EN-US>(</span>저작물<span lang=EN-US>)</span>이
정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우<span lang=EN-US>, </span>다른 이용자는 관련법령이 정한
절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며<span lang=EN-US>, </span>“회사”는 관련법령에 따라 조치를
취할 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>④<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>“회사”는 전항에 따른 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 관련법령및 본 약관<span
lang=EN-US>, </span>기타 “회사” 정책에 위반되는 경우에는 관련법령에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 13 </span>조<span lang=EN-US> (</span>타 인터넷 사이트 관련<span lang=EN-US>)</span></span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>“사이트”상의
게시물 등에 다른 인터넷 사이트가 하이퍼링크<span lang=EN-US>(</span>하이퍼링크의 대상에는 문자<span
lang=EN-US>, </span>그림 및 동영상<span lang=EN-US>/</span>동화상 등 포함<span lang=EN-US>)</span>방식
등으로 연결된 경우<span lang=EN-US>, </span>다른 인터넷 사이트가 제공하는 서비스에 대해 회사는 책임을 지지 않습니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 14 </span>조<span lang=EN-US> (</span>분쟁해결<span lang=EN-US>)</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>”회사”는 “이용자”가 제기하는 정당한 의견이나 불만을 반영하고 관련된 사항을 처리하기 위하여 아래와 같이
고객센터를 지정하여 운영합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span class=MsoHyperlink><span lang=EN-US style='color:
black;text-decoration:none'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='color:black'>홈페이지 <span lang=EN-US>: </span></span><span
lang=EN-US><a href="https://www.beatcorp.io/">https://www.beatcorp.io/</a></span></p>

<p class=MsoListParagraph style='margin-left:60.0pt;text-indent:-20.0pt;
line-height:normal'><span lang=EN-US style='color:black'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>전화번호<span lang=EN-US> : 1661-1167</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>”회사”는 “이용자”의 불만사항 및 의견을 신속하게 처리합니다<span lang=EN-US>. </span>다만<span
lang=EN-US>, </span>신속한 처리가 곤란한 경우에는 “이용자”에게 그 사유와 처리일정을 지체없이 통보합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span style='color:black'>제<span
lang=EN-US> 15 </span>조<span lang=EN-US> (</span>관할 법원<span lang=EN-US>)</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>①<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>본 약관은 대한민국법령에 의하여 규정되고 이행되며<span lang=EN-US>, </span>본 약관에서
정하지 아니한 사항은 관련법령 및 일반 관례에 따르기로 합니다<span lang=EN-US>.</span></span></p>

<p class=MsoListParagraph style='margin-left:38.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='color:black'>②<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span><span
style='color:black'>서비스 이용과 관련하여 “회사”와 “이용자” 사이에 분쟁이 발생한 경우 우선 쌍방간의 분쟁의 해결을 위해 성실히
협의하며<span lang=EN-US>, </span>“회사”와 “이용자” 사이에 발생한 분쟁에 관한 소송의 관할은 “회사”의 본점 소재지를 관할하는
지방법원으로 합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US
style='color:black'>&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><span lang=EN-US>(</span>부칙<span
lang=EN-US>)</span></p>

<p class=MsoNormal style='line-height:normal'>본 약관은<span lang=EN-US> 2022</span>년
<span lang=EN-US>1</span>월 <span lang=EN-US>1</span>일부터 시행합니다<span lang=EN-US>.</span></p>

</div>

</body>

</html>

`;
