import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import CmtPopup from '@coremat/CmtPopup';
import AppRouter from './routes';

import 'react-virtualized/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    },
    mutations: {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <Provider store={store}>
          <CmtPopup />
          <AppRouter />
        </Provider>
      </AppContextProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
