import React from 'react';
import CmtVertical from '@coremat/CmtNavigation/Vertical';
import { useSelector } from 'react-redux';
import { isNull } from 'lodash';
import { bisMenus, menuManageStore, menuManageUsers, scmsMenus } from './data';
import { CFN_isAdmin } from 'utils/commFunction';
import { ENUM_PAGE } from '@bis/constants/enums';
import useSubscription from 'hook/useSubscription';

const SideBar = ({ height }) => {
  const memberRoleName = useSelector((state) => (state.auth.authUser ? state.auth.authUser.roleName : null));
  const storeCode = useSelector((state) => state.common.selectedStoreCode) || 'ALL';
  const storeList = useSelector((state) => state.auth.authUser?.storeList);
  const store = isNull(storeCode) ? '' : storeCode;
  const { page } = useSelector(({ common }) => common);
  const subscription = useSubscription();

  const childrenMenu = (store) => {
    const menus = page === ENUM_PAGE.SCMS ? scmsMenus(store) : bisMenus(store);
    const isBisAdmin = page === ENUM_PAGE.BIS && memberRoleName && CFN_isAdmin(memberRoleName);

    const filteredMenus = menus.map((menu) => {
      // if (menu.name === '실적 현황') {
      //   return {
      //     ...menu,
      //     children: menu.children.filter((child) => child.name !== '월별 분석'),
      //   };
      // } else
      if (menu.name === '장비 운영') {
        return {
          ...menu,
          children: menu.children.filter((child) => child.name !== '원자재 현황 예측'),
        };
      }
      return menu;
    });

    if (!isBisAdmin && store !== 'ALL') {
      const subscription = storeList.find((el) => el.code === store).subscription;
      const { product, is_experience } = subscription;
      const subscriptionService = product === 'PREMIUM' || is_experience;
      if (subscriptionService) {
        return menus;
      }
    }

    if (!isBisAdmin && !subscription) {
      return filteredMenus;
    } else {
      return menus;
    }
  };

  const getNavigationMenus = () => {
    const menus = [
      {
        type: 'section',
        children: childrenMenu(store),
      },
    ];

    // 운영보고서 개발망에만 반영, 상용망 반영 미정
    // if (process.env.REACT_APP_OPERATE_REPORT_VIEW === 'true') {
    //   menus[0].children.push(menuOperateReport(store));
    // }

    // 롤이 있고, 권한이 'ADMIN'이면 관리자 페이지를 등록 한다.
    if (memberRoleName && CFN_isAdmin(memberRoleName)) {
      menus.push({
        type: 'section',
        children: [menuManageStore, menuManageUsers],
      });
    }

    return menus;
  };

  return <CmtVertical menuItems={getNavigationMenus()} height={height} />;
};

export default SideBar;
