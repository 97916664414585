import { isNull } from 'lodash';

export const STORECODE_ALL = 'ALL';
export const STORECODE_ALL_BEAT = 'ALL_BEAT';
export const STORECODE_ALL_BEATBOX = 'ALL_BEATBOX';
export const STR_Zone = 'Zone-';
export const STR_dwelltime = 'dwelltime';
export const STR_ECONNABORTED = 'ECONNABORTED';
export const NUMBER_ZERO = 0;
export const NUMBER_MINUS_100 = -100;

export const SESSIONSTORAGE_KEY_selectedStoreCode = 'selectedStoreCode';
export const SESSIONSTORAGE_KEY_PeriodResult = 'PeriodResult';
export const SESSIONSTORAGE_KEY_PeriodPerformance = 'PeriodPerformance';
export const SESSIONSTORAGE_KEY_ComparePeriodType = 'ComparePeriodType';
export const SESSIONSTORAGE_KEY_PeriodOpIndicator = 'PeriodOpIndicator';
export const SESSIONSTORAGE_KEY_monthlyDate = 'monthlyDate';
export const SESSIONSTORAGE_KEY_loginState = 'loginState';
export const SESSIONSTORAGE_KEY_equipMode = 'equipMode';
export const SESSIONSTORAGE_KEY_equipmentOff = 'equipmentOff';
export const SESSIONSTORAGE_KEY_selectedDay = 'selectedDay';
export const SESSIONSTORAGE_KEY_PeriodOperation = 'PeriodOperation';
export const SESSIONSTORAGE_KEY_equipmentDate = 'equipmentDate';
export const SESSIONSTORAGE_KEY_equipmentBtnValue = 'equipmentBtnValue';
export const SESSIONSTORAGE_KEY_selectedScheduleMonth = 'selectedScheduleMonth';
export const SESSIONSTORAGE_KEY_selectedScheduleDate = 'selectedScheduleDate';

export const LOCALSTORAGE_KEY_autoLogin = 'autoLogin';

export const STR_date = 'date';
export const STR_startDate = 'startDate';
export const STR_endDate = 'endDate';
export const STR_compareStartDate = 'compareStartDate';
export const STR_compareEndDate = 'compareEndDate';

export const STR_initstore = 'initstore';

export const STR_lidarToken = 'lidarToken';

/**
 * v 값의 null, undefined 를 체크 해 본다.
 * @param {any} v
 * @returns
 */
export const isNullorUndefied = (v) => {
  if (isNull(v) || v === 'null' || v === 'undefined') {
    return true;
  }
  return false;
};

/**
 * service에서 사용할 부분인데, useIntl 가 잘 안된다.
 * 추후 방법을 찾아 보자.
 */
export const ERRORCODE_1004_KR = '등록되지 않은 이메일 주소이거나, 잘못된 이메일 주소 또는 비밀번호 입니다.';
export const ERRORCODE_9001_KR = '잘못된 이메일 주소 또는 비밀번호 입니다.';
export const ERRORCODE_9009_KR = '등록된 매장이 없어 로그인할 수 없습니다.';
export const ERROR_LOGINDISABLE_KR = '로그인 불가';

export const CONVERT_RATIO_TO_SECONDS = {
  openSecondsRatio: 'openSeconds',
  closedSecondsRatio: 'closedSeconds',
  abnormalitySecondsRatio: 'abnormalitySeconds',
  prevOpenSecondsRatio: 'prevOpenSeconds',
  prevClosedSecondsRatio: 'prevClosedSeconds',
  prevAbnormalitySecondsRatio: 'prevAbnormalitySeconds',
};

export const indicatorStatus = {
  OPEN: '정상',
  CLOSED: '점검중',
  ABNORMALITY: '비정상',
};
